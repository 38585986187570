import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Heading,
  HStack,
  SimpleGrid,
  useBreakpointValue,
  ChakraProvider,
  VStack,
  useColorModeValue,
  Text,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import Card from './components/Card';
import CreateSubscriptionLink from './components/CreateSubscriptionLink';
import CreateSubscriptionForm from './components/CreateSubscriptionForm';
import PlanCard from './components/PlanCard';
import axios from 'axios';
import 'simplebar/dist/simplebar.min.css';
import SimpleBar from 'simplebar-react';
import Ganancias from './components/Ganancias';
import BackNav from './components/BackendNav';
import Footer from './components/Footer';
import CrearLinkPagoForm from './components/CrearLinkPagoForm';
import MediaLibrary from './components/MediaLibrary';
import FileUpload from './components/FileUpload';
import { FaFolder, FaLink } from 'react-icons/fa';
import { FaGlobe } from 'react-icons/fa';
import { useAppContext } from './AppContext';
import PaymentLinkItem from './components/PaymentLinkItem';
import Menu from './components/Menu';

const Dashboard = () => {
  const [plans, setPlans] = useState([]);
  const numeroColunmas = useBreakpointValue({ base: 1, sm: 1, md: 1, lg: 2 });
  const [isLinksActive, setIsLinksActive] = useState('folder');
  const [reloadFlag, setReloadFlag] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [isPlanes, setIsPlanes] = useState(true);
  const [thumbs, setThumbs] = useState([]);
  const botonLinksRef = useRef(null);

  const reloadPlanes = () => {
    setReloadFlag(false);
    setTimeout(() => {
      setReloadFlag(true);
    }, 1000);
  };

  const reloadThumbs = () => {
    setShowFiles(false);
    axios
      .get(
        'https://server-mb9m.onrender.com/get-library-thumbs/' +
          localStorage.telegram_id
      )
      .then(res => {
        var tmpThumb = [];

        res.data.forEach(item => {
          // Crea un nuevo objeto en cada iteración
          var fileName = item.uploadName;
          var tipo = null;
          if (
            fileName.toLowerCase().endsWith('.mp4') ||
            fileName.toLowerCase().endsWith('.avi') ||
            fileName.toLowerCase().endsWith('.mkv') ||
            fileName.toLowerCase().endsWith('.mov') ||
            fileName.toLowerCase().endsWith('.wmv')
          ) {
            // Reemplaza la extensión por "jpg"
            var newFileName = fileName.replace(/\.[^.]+$/, '.jpg');
            tipo = 'video';
          } else {
            // No es un archivo de video, mantener el nombre de archivo original
            var newFileName = fileName;
            tipo = 'iamge';
          }
          var tmpThumbObject = {
            id: item.id,
            src:
              (window.location.hostname === 'localhost'
                ? 'http://127.0.0.1:5500'
                : 'https://estoy.live') +
              '/files/' +
              item.uuid +
              '/thumb-' +
              newFileName,
            type: tipo,
          };
          tmpThumb.push(tmpThumbObject);
        });
        setThumbs(tmpThumb);
        setShowFiles(true);
      });
  };

  const reloadLinks = () => {
    handleButtonClick('link');
    botonLinksRef.current.click();
  };

  useEffect(() => {
    axios
      .get(
        'https://server-mb9m.onrender.com/get-library-thumbs/' +
          localStorage.telegram_id
      )
      .then(res => {
        var tmpThumb = [];
        var tmpThumb2 = [];
        res.data.forEach(item => {
          // Crea un nuevo objeto en cada iteración
          var fileName = item.uploadName;
          var tipo = null;
          if (
            fileName.toLowerCase().endsWith('.mp4') ||
            fileName.toLowerCase().endsWith('.avi') ||
            fileName.toLowerCase().endsWith('.mkv') ||
            fileName.toLowerCase().endsWith('.mov') ||
            fileName.toLowerCase().endsWith('.wmv')
          ) {
            // Reemplaza la extensión por "jpg"
            var newFileName = fileName.replace(/\.[^.]+$/, '.jpg');
            tipo = 'video';
          } else {
            // No es un archivo de video, mantener el nombre de archivo original
            var newFileName = fileName;
            tipo = 'image';
          }
          const tmpThumbObject = {
            id: item.id,
            src:
              (window.location.hostname === 'localhost'
                ? 'http://127.0.0.1:5500'
                : 'https://estoy.live') +
              '/files/' +
              item.uuid +
              '/thumb-' +
              newFileName,
            type: tipo,
          };
          tmpThumb.push(tmpThumbObject);
        });

        console.log(tmpThumb);
        setThumbs(tmpThumb);
      });
    if (localStorage.getItem('chakra-ui-color-mode') === 'dark') {
      localStorage.setItem('chakra-ui-color-mode', 'light');
      window.location.reload(true);
    }
    const checkScreenSize = () => {
      if (window.innerWidth < 480) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const handleLinksPagoClick = val => {
    if (val === 'planes') {
      axios
        .get('/planes/' + localStorage.telegram_id)
        .then(response => {
          console.log(response);
          setPlans(response.data);
          setIsPlanes(true);
        })
        .catch(error => {
          console.error('Error en la solicitud:', error);
        });
    } else {
      HandleLoadPaymentLinks();
      setIsPlanes(false);
    }
  };

  useEffect(() => {
    axios
      .get('/planes/' + localStorage.telegram_id)
      .then(response => {
        console.log(response);
        setPlans(response.data);
      })
      .catch(error => {
        console.error('Error en la solicitud:', error);
      });
  }, [reloadFlag]);
  const backgroundColor = useColorModeValue('#f0dbeb', 'gray.900');

  const [showFiles, setShowFiles] = useState(true);

  const handleButtonClick = buttonName => {
    if (buttonName === 'folder') {
      setShowFiles(true);
    } else if (buttonName === 'link') {
      HandleLoadPaymentLinks();
      setShowFiles(false);
    }
  };
  const {
    formData,
    setFormData,
    createPaymentLink,
    selectedThumbs,
    loadPaymentLinks,
    HandleLoadPaymentLinks,
  } = useAppContext();

  useEffect(() => {
    console.log('a ver que onda', loadPaymentLinks[0]);
    if (loadPaymentLinks[0] === 'sin links') {
      loadPaymentLinks.length = 0;
    }
  }, [loadPaymentLinks, showFiles]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundColor="#2a0020"
    >
      <VStack>
        <BackNav />
        <Card>
          <Ganancias />
        </Card>
        <SimpleGrid columns={2} spacing={4} width={['80%', '100%', '100%']}>
          <Box
            background={
              isPlanes
                ? 'linear-gradient(to top, rgb(34, 16, 61), rgb(110, 38, 75))'
                : backgroundColor
            }
            borderColor={isPlanes ? 'white' : 'transparent'}
            borderWidth={isPlanes ? '3px' : '0px'}
            borderRadius="md"
            h={'41px'}
            _hover={{
              cursor: 'pointer',
            }}
            display="flex"
            alignItems="center" // Centra verticalmente el contenido
            justifyContent="center"
            onClick={() => handleLinksPagoClick('planes')}
          >
            <Text fontSize={'15px'} color={isPlanes ? 'white' : 'black'}>
              Planes
            </Text>
          </Box>
          <Box
            background={
              !isPlanes
                ? 'linear-gradient(to top, rgb(34, 16, 61), rgb(110, 38, 75))'
                : backgroundColor
            }
            borderRadius="md"
            borderColor={!isPlanes ? 'white' : 'transparent'}
            borderWidth={!isPlanes ? '3px' : '0px'}
            _hover={{
              cursor: 'pointer',
            }}
            display="flex"
            alignItems="center" // Centra verticalmente el contenido
            justifyContent="center"
            onClick={() => handleLinksPagoClick('pagos')}
          >
            <Text fontSize={'15px'} color={isPlanes ? 'black' : 'white'}>
              Links de pago
            </Text>
          </Box>
        </SimpleGrid>
        <SimpleGrid columns={[1, 2, 2]} spacing={4} width="100%">
          {isPlanes ? (
            <>
              <Card>
                <CreateSubscriptionForm triggerReloadPlan={reloadPlanes} />
              </Card>
              <Card>
                <Text
                  style={{
                    width: '100%',
                    display: 'block',
                    textAlign: 'left',
                    marginBottom: '11px',
                    fontSize: '11px',
                  }}
                >
                  Tus planes.
                </Text>
                <Box
                  style={{
                    borderTop: '1px solid rgb(204, 204, 204)',
                    boxShadow: ' rgb(0 0 0 / 7%) 0px 14px 40px -13px inset',
                  }}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  h="561px"
                >
                  <SimpleBar style={{ maxHeight: '100%', width: '100%' }}>
                    <SimpleGrid
                      columns={numeroColunmas}
                      spacing={3}
                      width="100%"
                    >
                      {reloadFlag &&
                        plans.map(plan => (
                          <PlanCard key={plan.id} plan={plan} />
                        ))}
                    </SimpleGrid>
                  </SimpleBar>
                </Box>
              </Card>
            </>
          ) : (
            <>
              <Card>
                <CrearLinkPagoForm triggerReloadLinks={reloadLinks} />
              </Card>
              <Card>
                <Box
                  style={{
                    borderTop: '1px solid rgb(204, 204, 204)',
                    boxShadow: ' rgb(0 0 0 / 7%) 0px 14px 40px -13px inset',
                    overflow: 'hidden',
                  }}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  h="561px"
                >
                  <Menu
                    handleButtonClick={handleButtonClick}
                    isLinksActive={isLinksActive}
                    botonLinksRef={botonLinksRef}
                  />
                  {showFiles ? (
                    <SimpleBar style={{ maxHeight: '100%', width: '100%' }}>
                      <FileUpload
                        telegram_id={localStorage.telegram_id}
                        triggerReloadThumbs={reloadThumbs}
                      />
                      <SimpleGrid columns={1} spacing={3} width="100%">
                        <MediaLibrary
                          thumbs={thumbs}
                          style={{ overflow: 'hidden', paddingBottom: '43px' }}
                        />
                      </SimpleGrid>
                    </SimpleBar>
                  ) : (
                    <SimpleBar style={{ maxHeight: '100%', width: '100%' }}>
                      <Accordion allowToggle>
                        {loadPaymentLinks.length > 0 &&
                        loadPaymentLinks[0] !== 'sin links' ? (
                          loadPaymentLinks.map((item, i) => (
                            <PaymentLinkItem key={i} item={item} index={i} />
                          ))
                        ) : (
                          <Text py={23}>Sin links, crea uno</Text>
                        )}
                      </Accordion>
                    </SimpleBar>
                  )}
                </Box>
              </Card>
            </>
          )}
        </SimpleGrid>
        <Footer />
      </VStack>
    </Box>
  );
};

export default Dashboard;
