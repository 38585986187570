import {
  Heading,
  Avatar,
  Box,
  Center,
  Image,
  Flex,
  Text,
  Stack,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import TelegramLoginSubscriptor from './TelegramLoginSubscriptor';
import React, { useState, useEffect } from 'react';

export default function SaleCard({ props }) {
  const { usuario_creador_id, descripcion, monto, country, plan_id, currency } =
    props;
  const [moneda, setMoneda] = useState();

  const [urlFoto, setUrlFoto] = useState();
  const url = 'https://estoy.live/datos-user/' + usuario_creador_id + '-1.jpg';
  function generateUniqueHash(length) {
    const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let hash = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      hash += charset.charAt(randomIndex);
    }

    return hash;
  }

  const countries = [
    { code: 'PY', name: 'Paraguay' },
    { code: 'PE', name: 'Perú' },
    { code: 'PA', name: 'Panamá' },
    { code: 'NG', name: 'Nigeria' },
    { code: 'MY', name: 'Malasia' },
    { code: 'MX', name: 'México' },
    { code: 'KE', name: 'Kenia' },
    { code: 'ID', name: 'Indonesia' },
    { code: 'GT', name: 'Guatemala' },
    { code: 'EC', name: 'Ecuador' },
    { code: 'CR', name: 'Costa Rica' },
    { code: 'CO', name: 'Colombia' },
    { code: 'CL', name: 'Chile' },
    { code: 'BR', name: 'Brasil' },
    { code: 'BO', name: 'Bolivia' },
    { code: 'AR', name: 'Argentina' },
  ];
  const boxBG = useColorModeValue('white', 'gray.800');
  const txtColor = useColorModeValue('gray.800', 'white');
  useEffect(() => {
    setMoneda(currency);
    // Genera un hash único de 8 caracteres
    const uniqueHash = generateUniqueHash(8);
    localStorage.setItem('hash', uniqueHash);
    console.log(uniqueHash);
    setUrlFoto(
      'https://estoy.live/datos-user/' + usuario_creador_id + '-1.jpg'
    );
    console.log(urlFoto);
  }, [urlFoto, setUrlFoto]);
  if (moneda !== undefined)
    return (
      <Center py={6}>
        <Box
          maxW={{ base: '353px' }}
          minW={{ base: '351px', md: '475px' }}
          w={'full'}
          bg={boxBG}
          boxShadow={'2xl'}
          rounded={'md'}
          overflow={'hidden'}
        >
          <Image
            h={'120px'}
            w={'full'}
            src={'https://estoy.live/sale-card-background.png'}
            objectFit="cover"
            alt="#"
          />
          <Flex justify={'center'} mt={-12}>
            <Avatar
              size={'xl'}
              src={urlFoto}
              css={{
                border: '2px solid white',
              }}
            />
          </Flex>

          <Box p={6}>
            <Stack spacing={0} align={'center'} mb={5}>
              <Heading
                fontSize={'2xl'}
                fontWeight={700}
                fontFamily={'body'}
                textTransform={'uppercase'}
                color={txtColor}
              >
                {descripcion}
              </Heading>
              <Text pb={'5px'} color={txtColor} style={{ fontWeight: '700' }}>
                Precio: {parseFloat(monto).toFixed(0) + ' ' + moneda}
              </Text>
            </Stack>
            <Stack direction={'row'} justify={'center'} spacing={6}></Stack>
            <TelegramLoginSubscriptor plan_id={plan_id} />
          </Box>
        </Box>
      </Center>
    );
}
