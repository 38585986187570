import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  Center,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import TelegramLoginButton from 'react-telegram-login';
import CountrySelect from './CountrySelect';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

function RegistrationForm() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [pais, setPais] = useState();
  useEffect(() => {
    axios.get('https://get.geojs.io/v1/ip/geo.json').then(response => {
      sessionStorage.pais = response.data.country_code;
      setPais(response.data.country_code);
    });
  }, []);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    country: '',
  });
  const [selectedCountry, setSelectedCountry] = useState('');

  const handleCountryChange = event => {
    console.log('dalehijodeputa', event.target.value);
    setPais(event.target.value);
    setFormData(prevData => ({
      ...prevData,
      country: event.target.value,
    }));
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [telegramResponse, setTelegramResponse] = useState({
    id: '',
    username: '',
  });
  const [combinedData, setCombinedData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    telegram_id: '',
    username: '',
    country: '',
  });

  const handleTelegramResponse = response => {
    // Aquí puedes manejar la respuesta de Telegram, como guardarla en el estado o enviarla al servidor

    setTelegramResponse(response);

    console.log(combinedData);
    console.log(formData.country);
    console.log(formData.password);
    console.log('Respuesta de Telegram:', response);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!telegramResponse.id) {
          console.log(
            'Datos de Telegram incompletos, no se realizará la llamada.'
          );
          return;
        }

        if (Object.values(combinedData).some(value => value === '')) {
          console.log(
            'Alguno de los valores está vacío, no se realizará la llamada.' +
              JSON.stringify(combinedData)
          );
          setCombinedData({
            first_name: formData.firstName,
            last_name: formData.lastName,
            email: formData.email,
            password: formData.password,
            telegram_id: telegramResponse.id,
            username: telegramResponse.username || null,
            country: formData.country === '' ? pais : formData.country,
            profile_pic: telegramResponse.photo_url || null,
          });
          console.log('que onda', JSON.stringify(combinedData));
        }

        const response = await axios
          .post(
            'https://server-mb9m.onrender.com/save-data-creadora',
            combinedData
          )
          .then(res => {
            const token = res.data.token;
            const user = res.data.user;
            localStorage.setItem('profile_pic', user.profile_pic);
            localStorage.setItem('telegram_id', user.telegram_id);
            localStorage.setItem('country', user.country);
            localStorage.setItem('firstname', user.first_name);
            localStorage.setItem('lastname', user.last_name);
            localStorage.setItem('chat_id', user.canal);
            localStorage.setItem('invitation_url', user.link_canal);
            login(token); // Llama al método de login del contexto de autenticación
            navigate('/dashboard');
          });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [telegramResponse, combinedData, formData, pais]);

  const isFormValid =
    formData.firstName &&
    formData.lastName &&
    formData.email &&
    formData.password;

  const [showPassword, setShowPassword] = useState();

  return (
    <Center>
      <VStack spacing={4} width={{ base: '200px', md: '400px' }}>
        <FormControl>
          <FormLabel color={'#f0dbeb'}>Nombre</FormLabel>
          <Input
            backgroundColor="#f0dbeb"
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel color={'#f0dbeb'}>Apellido</FormLabel>
          <Input
            backgroundColor="#f0dbeb"
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel color={'#f0dbeb'}>Email</FormLabel>
          <Input
            backgroundColor="#f0dbeb"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl id="password" isRequired>
          <FormLabel color={'#f0dbeb'}>Contraseña</FormLabel>
          <InputGroup>
            <Input
              backgroundColor="#f0dbeb"
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={formData.password}
              onChange={handleInputChange}
            />
            <InputRightElement h={'full'}>
              <Button
                variant={'ghost'}
                onClick={() => setShowPassword(showPassword => !showPassword)}
              >
                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl>
          <FormLabel color={'#f0dbeb'}>Pais</FormLabel>
          <CountrySelect onSelectCountry={handleCountryChange} />
        </FormControl>
        {isFormValid && (
          <Box>
            <TelegramLoginButton
              dataOnauth={handleTelegramResponse}
              botName="estoylive_bot"
            />
          </Box>
        )}
      </VStack>
    </Center>
  );
}

export default RegistrationForm;
