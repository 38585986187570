import { ReactElement } from 'react';
import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  Flex,
  Center,
  useColorModeValue,
} from '@chakra-ui/react';
import { FcAssistant, FcDonate, FcInTransit } from 'react-icons/fc';
import { FcApproval } from 'react-icons/fc';

interface FeatureProps {
  title: string;
  text: string;
  icon: ReactElement;
}

const Feature = ({ title, text, icon }: FeatureProps) => {
  return (
    <Stack>
      <Center>
        <Flex
          w={16}
          h={16}
          align={'center'}
          justify={'center'}
          color={'white'}
          rounded={'full'}
          bg={'gray.100'}
          mb={1}
          style={{
            backgroundImage: 'linear-gradient(to bottom, #22103d, #6e264b)',
          }}
        >
          {icon}
        </Flex>
      </Center>
      <Text color={useColorModeValue('white', 'white')} fontWeight={600}>
        {title}
      </Text>
      <Text color={'gray.500'}>{text}</Text>
    </Stack>
  );
};

export default function SimpleThreeColumns() {
  return (
    <Box p={4} background={useColorModeValue('#29123e', 'gray.900')}>
      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        spacing={10}
        py={25}
        px={{ base: 50, sm: 130, md: 150, lg: 150 }}
      >
        <Feature
          icon={<Icon as={FcAssistant} w={10} h={10} />}
          title={'Soporte personalizado'}
          text={
            'Encuentra la solucion a tus dudas o consultas con nuestro equipo 24/7'
          }
        />
        <Feature
          icon={<Icon as={FcDonate} w={10} h={10} />}
          title={'Medios de pago Locales'}
          text={
            'El proceso de pago para tus clientes sera transparente, fácil y familiar'
          }
        />
        <Feature
          icon={<Icon as={FcApproval} w={10} h={10} />}
          title={'Crece tu negocio'}
          text={
            'Tu solo preocupate por analizar el mercado, buscar oportunidades y generar el contenido adecuado.'
          }
        />
      </SimpleGrid>
    </Box>
  );
}
