import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Center,
  VStack,
  Heading,
} from '@chakra-ui/react';
import axios from 'axios';
import SaleCardWrapper from './SaleCardWrapper';

const Invite = () => {
  const [telegramId, setTelegramId] = useState(
    localStorage.telegram_id || null
  );
  const [planId, setPlanId] = useState(localStorage.plan_id || null);
  useEffect(() => {
    const plan_id = localStorage.plan_id || null;
    const email = localStorage.email || null;
    const telegram_id = localStorage.telegram_id || null;
    const token_plan =
      localStorage.planToekn || localStorage.url_pago.split('/')[5];
    console.log(token_plan);
    axios.post('https://server-mb9m.onrender.com/actualizar-sub', {
      plan_token: token_plan,
      telegram_id: telegram_id,
      email,
      plan_id,
    });
  }, []);

  const [loading, setLoading] = useState(false);
  const handleInvitacion = () => {
    setLoading(true);
    axios
      .get(
        'https://server-mb9m.onrender.com/inv-to-burn/' +
          telegramId +
          '-' +
          planId +
          '-' +
          localStorage.hash
      )
      .then(res => {
        console.log(res);
        setLoading(false);
        window.location.href = res.data.data[0].url_invitacion;
      });
  };
  return (
    <SaleCardWrapper>
      <Box width="100%" p={4} boxShadow="md" borderRadius="md" bg="white">
        <VStack spacing={4}>
          <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'}>
            Pago recibido!
          </Heading>
          <Button minW={'100%'} onClick={handleInvitacion} isLoading={loading}>
            Acceder al Grupo
          </Button>
        </VStack>
      </Box>
    </SaleCardWrapper>
  );
};

export default Invite;
