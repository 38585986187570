import React, { useState, useEffect } from 'react';
import {
  Box,
  SimpleGrid,
  Image,
  FormControl,
  FormLabel,
  Checkbox,
  Spinner,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { useAppContext } from '../AppContext';

function MediaLibrary({ thumbs }) {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState(
    new Array(thumbs.length).fill({ selected: false, id: null })
  );

  const [loadedImages, setLoadedImages] = useState(0);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);

  useEffect(() => {
    let imageCount = 0;
    thumbs.forEach((item, i) => {
      const img = new window.Image();
      img.src = item.src;
      img.onload = () => {
        imageCount++;
        if (imageCount === thumbs.length) {
          setAllImagesLoaded(true);
        }
      };
    });
    console.log(thumbs);
  }, [thumbs]);

  const handleImageClick = (index, id) => {
    const updatedCheckboxes = [...selectedCheckboxes];
    updatedCheckboxes[index] = {
      selected: !updatedCheckboxes[index].selected,
      id: id,
    };
    setSelectedCheckboxes(updatedCheckboxes);
    setSelectedThumbs(updatedCheckboxes);
  };

  const { selectedThumbs, setSelectedThumbs } = useAppContext();

  return (
    <>
      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="center">
                Images
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <SimpleGrid columns={[3, 2, 2]} spacing={1} p={5}>
              {thumbs
                .filter(item => item.type === 'image')
                .map((item, i) =>
                  allImagesLoaded ? (
                    <Box
                      key={i}
                      position="relative"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                      backgroundColor={'black'}
                    >
                      <Image
                        src={item.src}
                        w={'100%'}
                        alt="Imagen"
                        onClick={() => handleImageClick(i, item.id)}
                        maxW="100%"
                        maxH="100%"
                      />

                      <FormControl
                        position="absolute"
                        bottom="0"
                        right="0"
                        w={'auto'}
                        paddingRight="4px"
                        style={{ pointerEvents: 'none' }}
                      >
                        <Checkbox
                          id={`checkbox-${i}`}
                          name={`checkbox-${i}`}
                          value={item.id}
                          isChecked={selectedCheckboxes[i]?.selected}
                          w={'100%'}
                        />
                      </FormControl>
                    </Box>
                  ) : (
                    <Box key={i}>
                      <Spinner size="md" color="black" />
                    </Box>
                  )
                )}
            </SimpleGrid>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="center">
                Videos
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <SimpleGrid columns={[3, 2, 2]} spacing={1} p={5}>
              {thumbs
                .filter(item => item.type === 'video')
                .map((item, i) =>
                  allImagesLoaded ? (
                    <Box
                      key={i}
                      position="relative"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                      backgroundColor={'black'}
                    >
                      <Image
                        src={item.src}
                        w={'100%'}
                        alt="Imagen"
                        onClick={() => handleImageClick(i, item.id)}
                        maxW="100%"
                        maxH="100%"
                      />

                      <FormControl
                        position="absolute"
                        bottom="0"
                        right="0"
                        w={'auto'}
                        paddingRight="4px"
                        style={{ pointerEvents: 'none' }}
                      >
                        <Checkbox
                          id={`checkbox-${i}`}
                          name={`checkbox-${i}`}
                          value={item.id}
                          isChecked={selectedCheckboxes[i]?.selected}
                          w={'100%'}
                        />
                      </FormControl>
                    </Box>
                  ) : (
                    <Box key={i}>
                      <Spinner size="md" color="black" />
                    </Box>
                  )
                )}
            </SimpleGrid>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
}

export default React.memo(MediaLibrary);
