import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  AlertIcon,
  Alert,
  Box,
  Heading,
  Center,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { FaCopy } from 'react-icons/fa';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useState } from 'react';
export default NiceModal.create(({ link_inv = '', link_creadora = '' }) => {
  const modal = useModal();
  const [copied, setCopied] = useState(false);

  const handleEntrarClick = () => {};
  return (
    <>
      <Modal
        size="xl"
        id="infomodal"
        closeOnOverlayClick={false}
        isOpen={modal.visible}
        onClose={() => modal.hide()}
      >
        <ModalOverlay />
        <ModalContent bg={'white'}>
          <ModalHeader></ModalHeader>
          <ModalCloseButton color={'black'} />
          <ModalBody p={33}>
            <Box>
              <Box textAlign="center" py={3} px={6}>
                <Heading as="h2" size="xl" mt={6} mb={2}>
                  Politica de Privacidad
                </Heading>
              </Box>
              <Box p={3}>
                <p className="c3">
                  <span className="c14">Fonko </span>
                  <span className="c14">LLC </span>
                  <span className="c7 c9">
                    te informa sobre su Pol&iacute;tica de Privacidad respecto
                    del tratamiento y protecci&oacute;n de los datos de
                    car&aacute;cter personal de los usuarios y clientes que
                    puedan ser recabados por la navegaci&oacute;n o
                    contrataci&oacute;n de servicios a trav&eacute;s del sitio
                    Web{' '}
                  </span>
                  <span className="c14">estoy.live</span>
                  <span className="c4">.</span>
                </p>
                <p className="c3">
                  <span className="c4">
                    En este sentido, el Titular garantiza el cumplimiento de la
                    normativa vigente en materia de protecci&oacute;n de datos
                    personales, reflejada en la Ley Org&aacute;nica 3/2018, de 5
                    de diciembre, de Protecci&oacute;n de Datos Personales y de
                    Garant&iacute;a de Derechos Digitales (LOPD GDD). Cumple
                    tambi&eacute;n con el Reglamento (UE) 2016/679 del
                    Parlamento Europeo y del Consejo de 27 de abril de 2016
                    relativo a la protecci&oacute;n de las personas
                    f&iacute;sicas (RGPD).
                  </span>
                </p>
                <p className="c3">
                  <span className="c4">
                    El uso de sitio Web implica la aceptaci&oacute;n de esta
                    Pol&iacute;tica de Privacidad as&iacute; como las
                    condiciones incluidas en el Aviso Legal.
                  </span>
                </p>
                <h3 className="c10" id="h.osgu3afs9jmu">
                  <span className="c0">Identidad del responsable</span>
                </h3>
                <ul className="c13 lst-kix_od1ndqvnwvt6-0 start">
                  <li className="c5 li-bullet-0">
                    <span className="c7 c9">Titular:</span>
                    <span className="c7">&nbsp;</span>
                    <span className="c14">Fonko</span>
                    <span className="c14 c16">
                      &nbsp;LLC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c7 c9">Domicilio: </span>
                    <span className="c6">
                      7925 NW 12th ST, Suite 109 Doral, FL 33126
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c7 c9">
                      Correo electr&oacute;nico:hola@estoy.live
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c7 c9">Sitio Web:</span>
                    <span className="c7">&nbsp;</span>
                    <span className="c16 c14">estoy.live</span>
                  </li>
                </ul>
                <h3 className="c10" id="h.k2rgcmpa2dfm">
                  <span className="c0">
                    Principios aplicados en el tratamiento de datos
                  </span>
                </h3>
                <p className="c3">
                  <span className="c4">
                    En el tratamiento de tus datos personales, el Titular
                    aplicar&aacute; los siguientes principios que se ajustan a
                    las exigencias del nuevo reglamento europeo de
                    protecci&oacute;n de datos:
                  </span>
                </p>
                <ul className="c13 lst-kix_tpzqjqm0pq2m-0 start">
                  <li className="c5 li-bullet-0">
                    <span className="c7 c9 c15">
                      Principio de licitud, lealtad y transparencia:
                    </span>
                    <span className="c4">
                      &nbsp;El Titular siempre requerir&aacute; el
                      consentimiento para el tratamiento de tus datos personales
                      que puede ser para uno o varios fines espec&iacute;ficos
                      sobre los que te informar&aacute; previamente con absoluta
                      transparencia.
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c7 c15 c9">
                      Principio de minimizaci&oacute;n de datos:
                    </span>
                    <span className="c4">
                      &nbsp;El Titular te solicitar&aacute; solo los datos
                      estrictamente necesarios para el fin o los fines que los
                      solicita.
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c7 c15 c9">
                      Principio de limitaci&oacute;n del plazo de
                      conservaci&oacute;n:
                    </span>
                    <span className="c4">
                      &nbsp;Los datos se mantendr&aacute;n durante el tiempo
                      estrictamente necesario para el fin o los fines del
                      tratamiento.
                      <br />
                      El Titular te informar&aacute; del plazo de
                      conservaci&oacute;n correspondiente seg&uacute;n la
                      finalidad. En el caso de suscripciones, el Titular
                      revisar&aacute; peri&oacute;dicamente las listas y
                      eliminar&aacute; aquellos registros inactivos durante un
                      tiempo considerable.
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c7 c15 c9">
                      Principio de integridad y confidencialidad:
                    </span>
                    <span className="c4">
                      &nbsp;Tus datos ser&aacute;n tratados de tal manera que su
                      seguridad, confidencialidad e integridad est&eacute;
                      garantizada. Debes saber que el Titular toma las
                      precauciones necesarias para evitar el acceso no
                      autorizado o uso indebido de los datos de sus usuarios por
                      parte de terceros.
                    </span>
                  </li>
                </ul>
                <h3 className="c10" id="h.sr6w4x9jh6oe">
                  <span className="c0">
                    Obtenci&oacute;n de datos personales
                  </span>
                </h3>
                <p className="c3">
                  <span className="c7 c9">Para navegar por</span>
                  <span className="c7">&nbsp;</span>
                  <span className="c14">estoy.live</span>
                  <span className="c4">
                    &nbsp;no es necesario que facilites ning&uacute;n dato
                    personal. Los casos en los que s&iacute; proporcionas tus
                    datos personales son los siguientes:
                  </span>
                </p>
                <p className="c3 c12">
                  <span className="c1"></span>
                </p>
                <ul className="c13 lst-kix_f3w0wv198mso-0 start">
                  <li className="c5 li-bullet-0">
                    <span className="c4">
                      Al contactar a trav&eacute;s de los formularios de
                      contacto o enviar un correo electr&oacute;nico.
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c4">
                      Al realizar un comentario en un art&iacute;culo o
                      p&aacute;gina.
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c4">
                      Al inscribirte en un formulario de suscripci&oacute;n o un
                      bolet&iacute;n que el Titular gestiona con MailChimp.
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c4">
                      Al inscribirte en un formulario de suscripci&oacute;n o un
                      bolet&iacute;n que el Titular gestiona con MailRelay.
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c4">
                      Al inscribirte en un formulario de suscripci&oacute;n o un
                      bolet&iacute;n que el Titular gestiona con SendinBlue.
                    </span>
                  </li>
                </ul>
                <h3 className="c10" id="h.c8040v6t9ow">
                  <span className="c0">Tus derechos</span>
                </h3>
                <p className="c3">
                  <span className="c4">
                    El Titular te informa que sobre tus datos personales tienes
                    derecho a:
                  </span>
                </p>
                <ul className="c13 lst-kix_diue2n9z2ri3-0 start">
                  <li className="c5 li-bullet-0">
                    <span className="c4">
                      Solicitar el acceso a los datos almacenados.
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c4">
                      Solicitar una rectificaci&oacute;n o la
                      cancelaci&oacute;n.
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c4">
                      Solicitar la limitaci&oacute;n de su tratamiento.
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c4">Oponerte al tratamiento.</span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c4">
                      Solicitar la portabilidad de tus datos.
                    </span>
                  </li>
                </ul>
                <p className="c3">
                  <span className="c4">
                    El ejercicio de estos derechos es personal y por tanto debe
                    ser ejercido directamente por el interesado,
                    solicit&aacute;ndolo directamente al Titular, lo que
                    significa que cualquier cliente, suscriptor o colaborador
                    que haya facilitado sus datos en alg&uacute;n momento puede
                    dirigirse al Titular y pedir informaci&oacute;n sobre los
                    datos que tiene almacenados y c&oacute;mo los ha obtenido,
                    solicitar la rectificaci&oacute;n de los mismos, solicitar
                    la portabilidad de sus datos personales, oponerse al
                    tratamiento, limitar su uso o solicitar la
                    cancelaci&oacute;n de esos datos en los ficheros del
                    Titular.
                  </span>
                </p>
                <p className="c3">
                  <span className="c7 c9">
                    Para ejercitar tus derechos de acceso, rectificaci&oacute;n,
                    cancelaci&oacute;n, portabilidad y oposici&oacute;n tienes
                    que enviar un correo electr&oacute;nico a{' '}
                  </span>
                  <span className="c14">hola@estoy.live</span>
                  <span className="c4">
                    &nbsp;junto con la prueba v&aacute;lida en derecho como una
                    fotocopia del D.N.I. o equivalente.
                  </span>
                </p>
                <p className="c3">
                  <span className="c4">
                    Tienes derecho a la tutela judicial efectiva y a presentar
                    una reclamaci&oacute;n ante la autoridad de control, en este
                    caso, la Agencia Espa&ntilde;ola de Protecci&oacute;n de
                    Datos, si consideras que el tratamiento de datos personales
                    que te conciernen infringe el Reglamento.
                  </span>
                </p>
                <h3 className="c10" id="h.10tqn2kr1o4s">
                  <span className="c0">
                    Finalidad del tratamiento de datos personales
                  </span>
                </h3>
                <p className="c3">
                  <span className="c4">
                    Cuando te conectas al sitio Web para mandar un correo al
                    Titular, te suscribes a su bolet&iacute;n o realizas una
                    contrataci&oacute;n, est&aacute;s facilitando
                    informaci&oacute;n de car&aacute;cter personal de la que el
                    responsable es el Titular. Esta informaci&oacute;n puede
                    incluir datos de car&aacute;cter personal como pueden ser tu
                    direcci&oacute;n IP, nombre y apellidos, direcci&oacute;n
                    f&iacute;sica, direcci&oacute;n de correo
                    electr&oacute;nico, n&uacute;mero de tel&eacute;fono, y otra
                    informaci&oacute;n. Al facilitar esta informaci&oacute;n,
                    das tu consentimiento para que tu informaci&oacute;n sea
                    recopilada, utilizada, gestionada y almacenada por
                    superadmin.es , s&oacute;lo como se describe en el Aviso
                    Legal y en la presente Pol&iacute;tica de Privacidad.
                  </span>
                </p>
                <p className="c3">
                  <span className="c4">
                    Los datos personales y la finalidad del tratamiento por
                    parte del Titular es diferente seg&uacute;n el sistema de
                    captura de informaci&oacute;n:
                  </span>
                </p>
                <p className="c3 c12">
                  <span className="c1"></span>
                </p>
                <ul className="c13 lst-kix_7q7ctmgpynbw-0 start">
                  <li className="c5 li-bullet-0">
                    <span className="c7 c15 c9">Formularios de contacto:</span>
                    <span className="c4">
                      &nbsp;El Titular solicita datos personales entre los que
                      pueden estar: Nombre y apellidos, direcci&oacute;n de
                      correo electr&oacute;nico, n&uacute;mero de
                      tel&eacute;fono y direcci&oacute;n de tu sitio Web con la
                      finalidad de responder a tus consultas.
                      <br />
                      Por ejemplo, el Titular utiliza esos datos para dar
                      respuesta a tus mensajes, dudas, quejas, comentarios o
                      inquietudes que puedas tener relativas a la
                      informaci&oacute;n incluida en el sitio Web, los servicios
                      que se prestan a trav&eacute;s del sitio Web, el
                      tratamiento de tus datos personales, cuestiones referentes
                      a los textos legales incluidos en el sitio Web, as&iacute;
                      como cualquier otra consulta que puedas tener y que no
                      est&eacute; sujeta a las condiciones del sitio Web o de la
                      contrataci&oacute;n.
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c7 c15 c9">
                      Formularios de suscripci&oacute;n a contenidos:
                    </span>
                    <span className="c4">
                      &nbsp;El Titular solicita los siguientes datos personales:
                      Nombre y apellidos, direcci&oacute;n de correo
                      electr&oacute;nico, n&uacute;mero de tel&eacute;fono y
                      direcci&oacute;n de tu sitio web para gestionar la lista
                      de suscripciones, enviar boletines, promociones y ofertas
                      especiales.
                      <br />
                      Los datos que facilites al Titular estar&aacute;n ubicados
                      en los servidores de The Rocket Science Group LLC d/b/a,
                      con domicilio en EEUU. (Mailchimp).
                    </span>
                  </li>
                </ul>
                <p className="c3">
                  <span className="c4">
                    Existen otras finalidades por las que el Titular trata tus
                    datos personales:
                  </span>
                </p>
                <ul className="c13 lst-kix_yy3k7j5xh0sc-0 start">
                  <li className="c5 li-bullet-0">
                    <span className="c4">
                      Para garantizar el cumplimiento de las condiciones
                      recogidas en el Aviso Legal y en la ley aplicable. Esto
                      puede incluir el desarrollo de herramientas y algoritmos
                      que ayuden a este sitio Web a garantizar la
                      confidencialidad de los datos personales que recoge.
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c4">
                      Para apoyar y mejorar los servicios que ofrece este sitio
                      Web.
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c4">
                      Para analizar la navegaci&oacute;n. El Titular recoge
                      otros datos no identificativos que se obtienen mediante el
                      uso de cookies que se descargan en tu ordenador cuando
                      navegas por el sitio Web cuyas caracter&iacute;siticas y
                      finalidad est&aacute;n detalladas en la Pol&iacute;tica de
                      Cookies .
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c4">
                      Para gestionar las redes sociales. el Titular tiene
                      presencia en redes sociales. Si te haces seguidor en las
                      redes sociales del Titular el tratamiento de los datos
                      personales se regir&aacute; por este apartado, as&iacute;
                      como por aquellas condiciones de uso, pol&iacute;ticas de
                      privacidad y normativas de acceso que pertenezcan a la red
                      social que proceda en cada caso y que has aceptado
                      previamente.
                    </span>
                  </li>
                </ul>
                <p className="c3">
                  <span className="c7 c9">
                    Puedes consultar las pol&iacute;ticas de privacidad de las
                    principales redes sociales en estos enlaces:
                  </span>
                </p>
                <ul className="c13 lst-kix_vgi5xkyczjqi-0 start">
                  <li className="c5 li-bullet-0">
                    <span className="c4">Facebook</span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c4">Twitter</span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c4">Linkedin</span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c4">YouTube</span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c4">Instagram</span>
                  </li>
                </ul>
                <p className="c3">
                  <span className="c4">
                    El Titular tratar&aacute; tus datos personales con la
                    finalidad de administrar correctamente su presencia en la
                    red social, informarte de sus actividades, productos o
                    servicios, as&iacute; como para cualquier otra finalidad que
                    las normativas de las redes sociales permitan.
                  </span>
                </p>
                <p className="c3">
                  <span className="c4">
                    En ning&uacute;n caso el Titular utilizar&aacute; los
                    perfiles de seguidores en redes sociales para enviar
                    publicidad de manera individual.
                  </span>
                </p>
                <h3 className="c10" id="h.dqovzz9bas8e">
                  <span className="c0">Seguridad de los datos personales</span>
                </h3>
                <p className="c3">
                  <span className="c4">
                    Para proteger tus datos personales, el Titular toma todas
                    las precauciones razonables y sigue las mejores
                    pr&aacute;cticas de la industria para evitar su
                    p&eacute;rdida, mal uso, acceso indebido,
                    divulgaci&oacute;n, alteraci&oacute;n o destrucci&oacute;n
                    de los mismos.
                  </span>
                </p>
                <p className="c3">
                  <span className="c7 c9">
                    El sitio Web est&aacute; alojado en{' '}
                  </span>
                  <span className="c14">Dreamhost</span>
                  <span className="c4">
                    . La seguridad de tus datos est&aacute; garantizada, ya que
                    toman todas las medidas de seguridad necesarias para ello.
                    Puedes consultar su pol&iacute;tica de privacidad para tener
                    m&aacute;s informaci&oacute;n.
                  </span>
                </p>
                <h3 className="c10" id="h.d4n6pxo759ph">
                  <span className="c0">Contenido de otros sitios web</span>
                </h3>
                <p className="c3">
                  <span className="c4">
                    Las p&aacute;ginas de este sitio Web pueden incluir
                    contenido incrustado (por ejemplo, v&iacute;deos,
                    im&aacute;genes, art&iacute;culos, etc.). El contenido
                    incrustado de otras web se comporta exactamente de la misma
                    manera que si hubieras visitado la otra web.
                  </span>
                </p>
                <p className="c3">
                  <span className="c4">
                    Estos sitios Web pueden recopilar datos sobre ti, utilizar
                    cookies, incrustar un c&oacute;digo de seguimiento adicional
                    de terceros, y supervisar tu interacci&oacute;n usando este
                    c&oacute;digo.
                  </span>
                </p>
                <h3 className="c10" id="h.ozgjcvts0q3f">
                  <span className="c0">Pol&iacute;tica de Cookies</span>
                </h3>
                <p className="c3">
                  <span className="c4">
                    Para que este sitio Web funcione correctamente necesita
                    utilizar cookies, que es una informaci&oacute;n que se
                    almacena en tu navegador web.
                  </span>
                </p>
                <p className="c3">
                  <span className="c4">
                    En la p&aacute;gina Pol&iacute;tica de Cookies puedes
                    consultar toda la informaci&oacute;n relativa a la
                    pol&iacute;tica de recogida, la finalidad y el tratamiento
                    de las cookies.
                  </span>
                </p>
                <h3 className="c10" id="h.nwytthsypx82">
                  <span className="c0">
                    Legitimaci&oacute;n para el tratamiento de datos
                  </span>
                </h3>
                <p className="c3">
                  <span className="c4">
                    La base legal para el tratamiento de tus datos es: el
                    consentimiento.
                  </span>
                </p>
                <p className="c3">
                  <span className="c4">
                    Para contactar con el Titular, suscribirte a un
                    bolet&iacute;n o realizar comentarios en este sitio Web
                    tienes que aceptar la presente Pol&iacute;tica de
                    Privacidad.
                  </span>
                </p>
                <h3 className="c10" id="h.x2vindqkg2fs">
                  <span className="c0">
                    Categor&iacute;as de datos personales
                  </span>
                </h3>
                <p className="c3">
                  <span className="c4">
                    Las categor&iacute;as de datos personales que trata el
                    Titular son:
                  </span>
                </p>
                <ul className="c13 lst-kix_k8mvjzywf18r-0 start">
                  <li className="c5 li-bullet-0">
                    <span className="c4">Datos identificativos.</span>
                  </li>
                </ul>
                <h3 className="c10" id="h.t7v07fo1awks">
                  <span className="c0">
                    Conservaci&oacute;n de datos personales
                  </span>
                </h3>
                <p className="c3">
                  <span className="c4">
                    Los datos personales que proporciones al Titular se
                    conservar&aacute;n hasta que solicites su supresi&oacute;n.
                  </span>
                </p>
                <h3 className="c10" id="h.2kz2l236mv78">
                  <span className="c0">Destinatarios de datos personales</span>
                </h3>
                <ul className="c13 lst-kix_vn5w6tem49up-0 start">
                  <li className="c5 li-bullet-0">
                    <span className="c7 c15 c9">Mailrelay</span>
                    <span className="c7 c9">
                      &nbsp;CPC Servicios Inform&aacute;ticos Aplicados a Nuevas
                      Tecnolog&iacute;as S.L. (en adelante &ldquo;CPC&rdquo;) ,
                      con domicilio social en C/ Nardo, 12 28250 &ndash;
                      Torrelodones &ndash; Madrid.
                      <br />
                      Encontrar&aacute;s m&aacute;s informaci&oacute;n en:{' '}
                    </span>
                    <span className="c7 c8">
                      <a
                        className="c2"
                        href="https://www.google.com/url?q=https://mailrelay.com/&amp;sa=D&amp;source=editors&amp;ust=1668028351710034&amp;usg=AOvVaw2Ii430WaH47sdWOOG7_EBt"
                      >
                        https://mailrelay.com
                        <br />
                      </a>
                    </span>
                    <span className="c4">
                      CPC trata los datos con la finalidad de prestar sus
                      servicios de email el Titulareting al Titular.
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c7 c15 c9">Mailchimp</span>
                    <span className="c7 c9">
                      &nbsp;The Rocket Science Group LLC d/b/a , con domicilio
                      en EEUU.
                      <br />
                      Encontrar&aacute;s m&aacute;s informaci&oacute;n en:{' '}
                    </span>
                    <span className="c7 c8">
                      <a
                        className="c2"
                        href="https://www.google.com/url?q=https://mailchimp.com/&amp;sa=D&amp;source=editors&amp;ust=1668028351710782&amp;usg=AOvVaw09MGtkyvcNb4gUjEcYCEyR"
                      >
                        https://mailchimp.com
                        <br />
                      </a>
                    </span>
                    <span className="c4">
                      The Rocket Science Group LLC d/b/a trata los datos con la
                      finalidad de prestar sus servicios de email el
                      Titulareting al Titular.
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c7 c15 c9">SendinBlue</span>
                    <span className="c7 c9">
                      &nbsp;SendinBlue, sociedad por acciones simplificada
                      (soci&eacute;t&eacute; par actions simplifi&eacute;e)
                      inscrita en el Registro Mercantil de Par&iacute;s con el
                      n&uacute;mero 498 019 298, con domicilio social situado en
                      55 rue d&rsquo;Amsterdam, 75008, Par&iacute;s (Francia).
                      <br />
                      Encontrar&aacute;s m&aacute;s informaci&oacute;n en:{' '}
                    </span>
                    <span className="c7 c8">
                      <a
                        className="c2"
                        href="https://www.google.com/url?q=https://es.sendinblue.com/&amp;sa=D&amp;source=editors&amp;ust=1668028351711464&amp;usg=AOvVaw29oZIj9C-2Kqg_7x5qtaoL"
                      >
                        https://es.sendinblue.com
                        <br />
                      </a>
                    </span>
                    <span className="c4">
                      SendinBlue trata los datos con la finalidad de ofrecer
                      soluciones para el env&iacute;o de correos
                      electr&oacute;nicos, SMS transaccionales y de el
                      Titulareting al Titular.
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c7 c15 c9">Google Analytics</span>
                    <span className="c7 c9">
                      &nbsp;es un servicio de anal&iacute;tica web prestado por
                      Google, Inc., una compa&ntilde;&iacute;a de Delaware cuya
                      oficina principal est&aacute; en 1600 Amphitheatre
                      Parkway, Mountain View (California), CA 94043, Estados
                      Unidos (&ldquo;Google&rdquo;). Encontrar&aacute;s
                      m&aacute;s informaci&oacute;n en:{' '}
                    </span>
                    <span className="c7 c8">
                      <a
                        className="c2"
                        href="https://www.google.com/url?q=https://analytics.google.com/&amp;sa=D&amp;source=editors&amp;ust=1668028351712199&amp;usg=AOvVaw1yPpre7sMtid0habRsLFNm"
                      >
                        https://analytics.google.com
                        <br />
                      </a>
                    </span>
                    <span className="c4">
                      Google Analytics utiliza &ldquo;cookies&rdquo;, que son
                      archivos de texto ubicados en tu ordenador, para ayudar al
                      Titular a analizar el uso que hacen los usuarios del sitio
                      Web. La informaci&oacute;n que genera la cookie acerca del
                      uso del sitio Web (incluyendo tu direcci&oacute;n IP)
                      ser&aacute; directamente transmitida y archivada por
                      Google en los servidores de Estados Unidos.
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c7 c15 c9">DoubleClick by Google</span>
                    <span className="c7 c9">
                      &nbsp;es un conjunto de servicios publicitarios
                      proporcionado por Google, Inc., una compa&ntilde;&iacute;a
                      de Delaware cuya oficina principal est&aacute; en 1600
                      Amphitheatre Parkway, Mountain View (California), CA
                      94043, Estados Unidos (&ldquo;Google&rdquo;).
                      <br />
                      Encontrar&aacute;s m&aacute;s informaci&oacute;n en:{' '}
                    </span>
                    <span className="c7 c8">
                      <a
                        className="c2"
                        href="https://www.google.com/url?q=https://www.doubleclickbygoogle.com/&amp;sa=D&amp;source=editors&amp;ust=1668028351713025&amp;usg=AOvVaw2mW2vtJYHfG9Ru6Ad17bft"
                      >
                        https://www.doubleclickbygoogle.com
                        <br />
                      </a>
                    </span>
                    <span className="c4">
                      DoubleClick utiliza &ldquo;cookies&rdquo;, que son
                      archivos de texto ubicados en tu ordenador y que sirven
                      para aumentar la relevancia de los anuncios relacionados
                      con tus b&uacute;squedas recientes. En la Pol&iacute;tica
                      de privacidad de Google se explica c&oacute;mo Google
                      gestiona tu privacidad en lo que respecta al uso de las
                      cookies y otra informaci&oacute;n.
                    </span>
                  </li>
                </ul>
                <p className="c3">
                  <span className="c4">
                    Tambi&eacute;n puedes ver una lista de los tipos de cookies
                    que utiliza Google y sus colaboradores y toda la
                    informaci&oacute;n relativa al uso que hacen de cookies
                    publicitarias.
                  </span>
                </p>
                <h3 className="c10" id="h.nukw1w4xjwaq">
                  <span className="c0">Navegaci&oacute;n Web</span>
                </h3>
                <p className="c3">
                  <span className="c7 c9">Al navegar por </span>
                  <span className="c14">estoy.live</span>
                  <span className="c4">
                    &nbsp;se pueden recoger datos no identificativos, que pueden
                    incluir, la direcci&oacute;n IP, geolocalizaci&oacute;n, un
                    registro de c&oacute;mo se utilizan los servicios y sitios,
                    h&aacute;bitos de navegaci&oacute;n y otros datos que no
                    pueden ser utilizados para identificarte.
                  </span>
                </p>
                <p className="c3">
                  <span className="c7 c9">
                    El sitio Web utiliza los siguientes servicios de
                    an&aacute;lisis de terceros:
                  </span>
                </p>
                <ul className="c13 lst-kix_xttp6tgmw41h-0 start">
                  <li className="c5 li-bullet-0">
                    <span className="c4">Google Analytics</span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c4">DoubleClick por Google</span>
                  </li>
                </ul>
                <p className="c3">
                  <span className="c4">
                    El Titular utiliza la informaci&oacute;n obtenida para
                    obtener datos estad&iacute;sticos, analizar tendencias,
                    administrar el sitio, estudiar patrones de navegaci&oacute;n
                    y para recopilar informaci&oacute;n demogr&aacute;fica.
                  </span>
                </p>
                <h3 className="c10" id="h.lge7k56jvz4m">
                  <span className="c0">
                    Exactitud y veracidad de los datos personales
                  </span>
                </h3>
                <p className="c3">
                  <span className="c4">
                    Te comprometes a que los datos facilitados al Titular sean
                    correctos, completos, exactos y vigentes, as&iacute; como a
                    mantenerlos debidamente actualizados.
                  </span>
                </p>
                <p className="c3">
                  <span className="c4">
                    Como Usuario del sitio Web eres el &uacute;nico responsable
                    de la veracidad y correcci&oacute;n de los datos que remitas
                    al sitio exonerando a el Titular de cualquier
                    responsabilidad al respecto.
                  </span>
                </p>
                <h3 className="c10" id="h.1n28lh42hyoh">
                  <span className="c0">Aceptaci&oacute;n y consentimiento</span>
                </h3>
                <p className="c3">
                  <span className="c4">
                    Como Usuario del sitio Web declaras haber sido informado de
                    las condiciones sobre protecci&oacute;n de datos de
                    car&aacute;cter personal, aceptas y consientes el
                    tratamiento de los mismos por parte de el Titular en la
                    forma y para las finalidades indicadas en esta
                    Pol&iacute;tica de Privacidad.
                  </span>
                </p>
                <h3 className="c10" id="h.5lmz5x4qpp1a">
                  <span className="c0">Revocabilidad</span>
                </h3>
                <p className="c3">
                  <span className="c7 c9">
                    Para ejercitar tus derechos de acceso, rectificaci&oacute;n,
                    cancelaci&oacute;n, portabilidad y oposici&oacute;n tienes
                    que enviar un correo electr&oacute;nico a{' '}
                  </span>
                  <span className="c14">hola@estoy.live</span>
                  <span className="c4">
                    &nbsp;junto con la prueba v&aacute;lida en derecho como una
                    fotocopia del D.N.I. o equivalente.
                  </span>
                </p>
                <p className="c3">
                  <span className="c4">
                    El ejercicio de tus derechos no incluye ning&uacute;n dato
                    que el Titular est&eacute; obligado a conservar con fines
                    administrativos, legales o de seguridad.
                  </span>
                </p>
                <h3 className="c10" id="h.2we5d1sr00hx">
                  <span className="c0">
                    Cambios en la Pol&iacute;tica de Privacidad
                  </span>
                </h3>
                <p className="c3">
                  <span className="c4">
                    El Titular se reserva el derecho a modificar la presente
                    Pol&iacute;tica de Privacidad para adaptarla a novedades
                    legislativas o jurisprudenciales, as&iacute; como a
                    pr&aacute;cticas de la industria.
                  </span>
                </p>
                <p className="c3">
                  <span className="c4">
                    Estas pol&iacute;ticas estar&aacute;n vigentes hasta que
                    sean modificadas por otras debidamente publicadas.
                  </span>
                </p>
                <hr />
                <p className="c11">
                  <span className="c16 c17"></span>
                </p>
                <p className="c11">
                  <span className="c16 c17"></span>
                </p>
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            {link_inv.indexOf('undefined') !== -1 ? (
              <Button onClick={() => window.location.reload()}>Recargar</Button>
            ) : (
              <Button onClick={() => modal.hide()}>Continuar</Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});
