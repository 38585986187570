import { ReactNode, useEffect, useState, useRef } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Center, Box, VStack, Spinner, Image } from '@chakra-ui/react';
import useAxios from '../hooks/useAxios';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';
import ReactPlayer from 'react-player';

function Player() {
  const [videoPaths, setVideoPaths] = useState([]);
  const [imagePaths, setImagePaths] = useState([]);
  let params = useParams();
  const { response, loading, error } = useAxios({
    method: 'get',
    url: '/cargar-pago/' + params.payment_id,
  });

  useEffect(() => {
    if (response !== null) {
      if (response.respuesta) {
        const medios = response.medios;
        const tmpArry = [];
        const tmpArry2 = [];
        medios.map(item => {
          var fileName = item.uploadName;
          if (
            fileName.toLowerCase().endsWith('.mp4') ||
            fileName.toLowerCase().endsWith('.avi') ||
            fileName.toLowerCase().endsWith('.mkv') ||
            fileName.toLowerCase().endsWith('.mov') ||
            fileName.toLowerCase().endsWith('.wmv')
          ) {
            var tmpPath =
              'https://estoy.live/files/' + item.uuid + '/' + fileName;
            tmpArry.push(tmpPath);

            setVideoPaths(tmpArry);
          } else {
            var tmpPath =
              'https://estoy.live/files/' + item.uuid + '/' + fileName;
            tmpArry2.push(tmpPath);
          }
          setImagePaths(tmpArry2);
        });
      }
    }
  }, [response]);

  return (
    <Accordion allowToggle>
      {imagePaths.map((imagePath, index) => (
        <AccordionItem key={index}>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Image {index + 1}
              </Box>
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <Image src={imagePath} width="100%" />
          </AccordionPanel>
        </AccordionItem>
      ))}
      {videoPaths.map((videoPath, index) => (
        <AccordionItem key={index}>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Video {index + 1}
              </Box>
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <ReactPlayer url={videoPath} controls width="100%" />
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
}

export default Player;
