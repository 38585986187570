import { useState, useEffect } from 'react';
import {
  Button,
  Box,
  Center,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Container,
  useToast,
  Select,
  Flex,
  HStack,
  VStack,
  Spinner,
  Checkbox,
  Link,
} from '@chakra-ui/react';
import './CreateSubsForm.css';
import axios from 'axios';
import emojiRegex from 'emoji-regex';

const CreateSubscriptionForm = ({ triggerReloadPlan }) => {
  const [selectedChatIds, setSelectedChatIds] = useState([]);
  const invitationUrlsFromLocalStorage =
    localStorage.getItem('invitation_url').split(',') || [];
  const chatIdsFromLocalStorage =
    localStorage.getItem('chat_id').split(',') || [];

  const [isLoading, setIsLoading] = useState(false);
  const [errores, setErrores] = useState(false);
  const [inputFocus, setInputFocus] = useState(false); // Nuevo estado para controlar el foco
  const handleNeedInvLink = () => {
    alert(
      'Necesitas enviar el comando /crearlink en el canal para que el bot cree un link de invitacion'
    );
  };

  useEffect(() => {
    console.log(chatIdsFromLocalStorage.length);
  }, []);

  const toast = useToast();
  const [formData, setFormData] = useState({
    namePlanWithUSERID: '',
    periodo: '',
    currency: '',
    pais: null,
    precio: null,
  });
  const emojiPattern = emojiRegex();
  // Función para validar el contenido del campo de texto en tiempo real

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      // Aquí deberías realizar la solicitud HTTP a la API para crear la suscripción
      // Utiliza la URL: "https://api-sbx.dlocalgo.com/v1/subscription/plan"
      // y envía formData en el cuerpo de la solicitud
      // No olvides manejar la respuesta y posibles errores
      const selectedChatData = selectedChatIds.map(chatId => ({
        chat_id: chatId,
        // Otros campos relacionados al chat_id si es necesario
      }));
      console.log(
        formData.precio != 0,
        formData.namePlanWithUSERID !== '',
        formData.periodo != 0,
        selectedChatData.length > 0
      );

      if (emojiPattern.test(formData.namePlanWithUSERID)) {
        // Si se encuentra un emoji, elimínalo del valor del campo
        const textoLimpio = formData.namePlanWithUSERID.replace(
          emojiPattern,
          ''
        );
        formData.namePlanWithUSERID = textoLimpio;
      }
      if (
        formData.precio != 0 &&
        formData.namePlanWithUSERID !== '' &&
        formData.periodo != 0 &&
        selectedChatData.length > 0
      ) {
        setIsLoading(true);

        const requestData = {
          ...formData,
          selectedChats: selectedChatData,
        };
        console.log(
          'simulacion creada: ',
          requestData,
          selectedChatData.length,
          selectedChatIds.length
        );

        axios
          .post('https://server-mb9m.onrender.com/plan', requestData) // Cambia la URL a la ruta correcta para tu servidor
          .then(response => {
            console.log(response);
            setIsLoading(false);
            toast({
              title: `Plan ${JSON.stringify(
                response.data.resultado
              )} creado con éxito`,
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
            triggerReloadPlan();
          })
          .catch(error => {
            console.error('Error en la solicitud:', error);
            toast({
              title: 'Error al crear el plan',
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          });
      } else {
        setErrores(true);
        setTimeout(() => {
          setErrores(false);
        }, 2000);
      }
    } catch (error) {
      console.error('Error al crear la suscripción:', error);
    }
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    if (name === 'namePlanWithUSERID') {
      setFormData(prevData => ({
        ...prevData,
        [name]: value + '-' + localStorage.telegram_id,
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const countries = [
    { code: 'PY', name: 'Paraguay' },
    { code: 'PE', name: 'Perú' },
    { code: 'PA', name: 'Panamá' },
    { code: 'NG', name: 'Nigeria' },
    { code: 'MY', name: 'Malasia' },
    { code: 'MX', name: 'México' },
    { code: 'KE', name: 'Kenia' },
    { code: 'ID', name: 'Indonesia' },
    { code: 'GT', name: 'Guatemala' },
    { code: 'EC', name: 'Ecuador' },
    { code: 'CR', name: 'Costa Rica' },
    { code: 'CO', name: 'Colombia' },
    { code: 'CL', name: 'Chile' },
    { code: 'BR', name: 'Brasil' },
    { code: 'BO', name: 'Bolivia' },
    { code: 'AR', name: 'Argentina' },
  ];

  return (
    <Container
      style={{
        backgroundImage:
          'linear-gradient(to top, rgb(34, 16, 61), rgb(110, 38, 75))',
        color: 'white',
      }}
      maxW="md"
      mt={8}
      p={6}
      pb={15}
      boxShadow={inputFocus ? 'lg' : 'none'} // Aplica sombra solo cuando un input tiene focus
      rounded="lg"
      bg="white"
      className={inputFocus ? 'container-focused' : ''} // Aplica clase cuando algún input tiene focus
    >
      <Heading
        style={{
          fontSize: '32px !important',
          paddingBottom: '27px',
          color: 'white',
        }}
      >
        + Nuevo Plan:
      </Heading>
      <form onSubmit={handleSubmit}>
        <FormControl mb={4}>
          <FormLabel fontSize={'12px'}>Nombra tu plan</FormLabel>
          <Input
            type="text"
            color="black"
            background={'white'}
            name="namePlanWithUSERID"
            value={formData.namePlanWithUSERID.split('-')[0]}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel fontSize={'12px'}>Frecuencia</FormLabel>
          <Select
            name="periodo"
            background={'white'}
            color="black"
            value={formData.periodo}
            onChange={handleInputChange}
          >
            <option value="0">Seleccionar</option>
            <option value="1">1 día</option>
            <option value="5">5 días</option>
            <option value="7">7 días</option>
            <option value="15">15 días</option>
            <option value="30">30 días</option>
          </Select>
        </FormControl>
        <FormControl mb={4}>
          <FormLabel fontSize={'12px'}>País</FormLabel>
          <Select
            name="pais"
            value={formData.pais}
            color="black"
            background={'white'}
            onChange={handleInputChange}
          >
            <option value="0">Todos</option>
            {countries.map(country => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl mb={4}>
          <FormLabel fontSize={'12px'}>Precio</FormLabel>
          <Input
            type="text"
            background={'white'}
            color="black"
            name="precio"
            value={formData.precio}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl
          mb={4}
          mt={31}
          style={errores ? { border: '1px solid red' } : { border: '0px' }}
        >
          <FormLabel fontSize={'12px'}>Canal Telegram asociado:</FormLabel>
          {JSON.stringify(chatIdsFromLocalStorage) != JSON.stringify(['']) ? (
            chatIdsFromLocalStorage.map((chatId, index) => (
              <Flex
                key={index}
                align="center"
                justifyContent="space-between"
                width={'100%'}
              >
                {invitationUrlsFromLocalStorage[index] !== 'null' ? (
                  <HStack width={'100%'}>
                    <Checkbox
                      marginRight={'19'}
                      background={'white'}
                      value={chatId}
                      isChecked={selectedChatIds.includes(chatId)}
                      onChange={e => {
                        const selectedId = e.target.value;
                        setSelectedChatIds(prevSelected =>
                          prevSelected.includes(selectedId)
                            ? prevSelected.filter(id => id !== selectedId)
                            : [...prevSelected, selectedId]
                        );
                      }}
                    />
                    <Box>
                      <Link
                        href={invitationUrlsFromLocalStorage[index]}
                        isExternal
                      >
                        {chatId}
                      </Link>
                    </Box>
                  </HStack>
                ) : (
                  <>
                    <Checkbox
                      value={chatId}
                      isDisabled
                      color="black"
                      isChecked={selectedChatIds.includes(chatId)}
                      onChange={e => {
                        const selectedId = e.target.value;
                        setSelectedChatIds(prevSelected =>
                          prevSelected.includes(selectedId)
                            ? prevSelected.filter(id => id !== selectedId)
                            : [...prevSelected, selectedId]
                        );
                      }}
                    />
                    <Link href="#" onClick={handleNeedInvLink}>
                      {chatId}
                    </Link>
                  </>
                )}
              </Flex>
            ))
          ) : (
            <Link href={'https://t.me/estoylive_bot?startgroup=true'}>
              Agrega el bot como admin
            </Link>
          )}
        </FormControl>

        <VStack mt={37}>
          <Button
            type="submit"
            minWidth="200px"
            colorScheme="blue"
            isLoading={isLoading}
            loadingText="Cargando..."
          >
            {isLoading ? 'Procesando...' : 'Crear'}
          </Button>
          {errores && <small>Completa todos los campos</small>}
        </VStack>
      </form>
    </Container>
  );
};

export default CreateSubscriptionForm;
