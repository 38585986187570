import React, { useState, useRef } from 'react';
import { SimpleGrid, Button } from '@chakra-ui/react';
import { FaFolder, FaLink } from 'react-icons/fa';

const Menu = ({ handleButtonClick, isLinksActive, botonLinksRef }) => {
  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClickInternal = buttonName => {
    setActiveButton(buttonName);
    handleButtonClick(buttonName);
  };

  return (
    <SimpleGrid columns={2} spacing={1} w={'100%'}>
      <Button
        leftIcon={<FaFolder />}
        colorScheme="blue"
        background={
          activeButton === 'folder'
            ? 'white'
            : 'linear-gradient(to top, rgb(34, 16, 61), rgb(110, 38, 75))'
        }
        _hover={{
          background:
            'linear-gradient(to top, rgb(34, 16, 61),rgb(178,62,128))',
          color: 'white ',
        }}
        color={activeButton === 'folder' ? 'black' : 'white'}
        onClick={() => handleButtonClickInternal('folder')}
        _active={{ background: 'white', color: 'white' }}
        _before={{
          content: '""',
          position: 'absolute',
          width: '100%',
          height: '100%',
          background:
            activeButton !== 'folder'
              ? 'radial-gradient(ellipse at center, rgba(255,209,102,0.2) 0%, rgba(255,209,102,0) 70%)'
              : 'none',
          zIndex: -1,
          borderRadius: 'md',
        }}
      >
        Archivos
      </Button>
      <Button
        ref={botonLinksRef}
        leftIcon={<FaLink />}
        background={
          activeButton === 'link'
            ? 'white'
            : 'linear-gradient(to top, rgb(34, 16, 61), rgb(110, 38, 75))'
        }
        _hover={{
          background:
            'linear-gradient(to top, rgb(34, 16, 61),rgb(178,62,128))',
          color: 'white ',
        }}
        color={activeButton === 'link' ? 'black' : 'white'}
        onClick={() => handleButtonClickInternal('link')}
        _active={{ background: 'white', color: 'white' }}
        _before={{
          content: '""',
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: 'WHITE',
          zIndex: -1,
          borderRadius: 'md',
        }}
      >
        Lniks Pago
      </Button>
    </SimpleGrid>
  );
};

export default Menu;
