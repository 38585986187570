import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Link,
} from '@chakra-ui/react';
import { useState } from 'react';

import TelegramLoginButton from 'react-telegram-login';
import RegistrationForm from './RegistrationForm';

export default function SignupCard() {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box minW={'100%'} bg={useColorModeValue('#2a0020', 'gray.900')}>
      <Stack
        spacing={8}
        mx={'auto'}
        maxW={'lg'}
        py={12}
        px={6}
        id="signUpFakeSection"
      >
        <Stack align={'center'}>
          <Text
            color={'white'}
            style={{ fontSize: '41px' }}
            textAlign={'center'}
          >
            Regístrate
          </Text>
          <Text fontSize={'lg'} color={'#f0dbeb'} pb={3}>
            Y comienza a monetizar hoy ✌️
          </Text>
        </Stack>
        <Box
          rounded={'lg'}
          style={{ margin: '0 auto' }}
          width={{ base: '310px', md: '410px' }}
          bg={useColorModeValue('#bd4b67', 'gray.700')}
          boxShadow={'lg'}
          p={8}
        >
          <Stack spacing={10} pt={2}>
            <RegistrationForm />
          </Stack>
          <Stack pt={6}>
            <Text align={'center'} color={'#f0dbeb'}>
              Ya tienes usuario?{' '}
              <Link href={'http://estoy.live/login'} color={'#f0dbeb'}>
                Entrar
              </Link>
            </Text>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}
