import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Text,
  Image,
  Link,
  useClipboard,
  VStack,
  HStack,
  SimpleGrid,
  useBreakpointValue,
  Divider,
  CloseButton,
  Skeleton,
  Spinner,
} from '@chakra-ui/react';
import { FaGlobe } from 'react-icons/fa';
import { BsPeopleFill } from 'react-icons/bs';
import SubscriptionModalDos from './SubscriptionModalDos';
import axios from 'axios';
import 'animate.css';

const PlanCard = ({ plan }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eliminado, setEliminado] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    id,
    name,
    description,
    country,
    amount,
    plan_token,
    active,
    frequency_type,
    frequency_value,
  } = plan;
  const { hasCopied, onCopy } = useClipboard(
    `https://${window.location.hostname + '/' + plan_token}`
  );
  const buttonSize = useBreakpointValue({ base: 'sm', md: 'lg' });
  const minimoWitdth = useBreakpointValue({ base: '181px', md: '40px' });
  const maxWidt = useBreakpointValue({ base: 150, sm: 176, md: 180, lg: 190 });
  const [show, setShow] = useState(active);
  const [isClicked, setIsClicked] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState();
  const [personita, setPersonita] = useState(false);
  const [datos, setDatos] = useState({
    plan_id: null,
    titulo: null,
    tipo: null,
    frecuencia: null,
  });

  useEffect(() => {
    async function fetchPersonita() {
      if (active) {
        setLoading(true);
        const response = await axios.get(
          `https://server-mb9m.onrender.com/personita/${plan.id}`
        );
        if (response.data.total > 0) {
          setPersonita(true);
        }
        setLoading(false);
      }
    }
    fetchPersonita();
  }, []);

  const fetchData = planId => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(
          `https://server-mb9m.onrender.com/plan/${planId}`
        );
        resolve(response.data.subscripciones);
      } catch (error) {
        console.error('Error fetching data:', error);
        reject(error);
      }
    });
  };

  const openModal = async () => {
    if (!personita) {
      setIsClicked(false);
      return;
    }
    try {
      setIsClicked(true);
      const subscripciones = fetchData(plan.id)
        .then(subscripciones => {
          setSubscriptionData(subscripciones);
          console.log(subscripciones);
          const datosPlan = subscripciones[0].plan;
          const data = JSON.parse(localStorage.planes_montos);
          const amount =
            data.find(item => item.plan_id === plan.id)?.recibido || 0;
          console.log(parseFloat(amount) / parseFloat(localStorage.dolar));
          setDatos({
            amount: (
              parseFloat(amount) / parseFloat(localStorage.dolar)
            ).toFixed(2),
            plan_id: datosPlan.id,
            titulo: datosPlan.name,
            tipo: datosPlan.frequency_type,
            frecuencia: datosPlan.frequency_value,
          });
          setIsModalOpen(true);
        })
        .catch(error => {
          // Manejar el error
          console.error('Error:', error);
        });
    } catch (error) {
      console.error('Error opening modal:', error);
    }
  };

  const closeModal = () => {
    setIsClicked(false);
    setIsModalOpen(false);
  };

  const anchoColumnas = useBreakpointValue({
    base: '181px',
    md: '182px',
    lg: '190px',
  });
  // Obtener la URL de la bandera según el país (por ejemplo, AR para Argentina)
  const flagUrl =
    country != null
      ? `https://flagcdn.com/w320/${country.toLowerCase()}.png`
      : null;

  const handleEliminarPlan = () => {
    const confirmDelete = window.confirm(
      '¿Estás seguro de que deseas eliminar este plan?'
    );
    if (confirmDelete) {
      setLoading(true);
      axios
        .get(`https://server-mb9m.onrender.com/eliminar_plan/${id}`)
        .then(res => {
          const data = res.data;
          console.log(res, id);
          if (data.id === id) {
            setLoading(false);
            setEliminado(true);
          }
        });
    }
  };

  if (loading) {
    return (
      <Box
        borderWidth="1px"
        borderRadius="lg"
        p={4}
        shadow="md"
        style={{
          minWidth: '210px',
          minHeight: '70px',
          width: '100%',
        }}
      >
        {/* Usar Skeleton para cada campo */}
        <VStack style={{ width: '100%' }}>
          <Skeleton height="20px" width="80%" />
          <Divider
            style={{ color: 'white', width: '100%', border: 'solid 1px' }}
          />
          <Skeleton height="13px" width="100%" />
          <Skeleton height="13px" width="100%" />
        </VStack>
        <Box
          style={{
            width: 'auto',
            height: '100%',
          }}
          display="flex"
          alignItems="flex-start"
        ></Box>
      </Box>
    );
  }
  if (!eliminado && show)
    return (
      <Box
        backgroundColor={personita ? '#880d359e' : '#dc1d5b9e'}
        mb={13}
        borderWidth="1px"
        borderRadius="lg"
        borderColor="black"
        p={4}
        shadow="md"
        style={{
          color: 'white',
          minWidth: '210px',
          minHeigh: '70x',
          width: '100%',
        }}
      >
        <SimpleGrid rows={2} spacing={4}>
          <HStack style={{ justifyContent: 'space-between' }}>
            {flagUrl ? (
              <Box
                borderRadius="50px"
                backgroundImage={`url('${flagUrl}')`}
                backgroundSize="cover"
                backgroundPosition="center"
                width="29px"
                height="29px"
                marginTop="39px"
                alt={country}
              ></Box>
            ) : (
              <FaGlobe />
            )}
            <VStack style={{ width: '69%' }}>
              <HStack>
                <Box>
                  {personita && (
                    <BsPeopleFill
                      color="lime"
                      className="animate__animated heartbeat"
                    />
                  )}
                </Box>
                <Box>
                  <Text
                    onClick={openModal}
                    cursor="pointer"
                    fontWeight="bold"
                    style={{
                      textTransform: 'uppercase',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      maxWidth: '160px',
                    }}
                  >
                    {isClicked ? <Spinner /> : name}
                  </Text>
                </Box>
              </HStack>
              <Divider
                style={{
                  color: 'white',
                  width: '100%',
                  border: 'solid 1px',
                  marginBottom: '11px',
                }}
              />
              <Text
                style={{
                  color: 'white',
                  fontWeight: '700',
                  textAlign: 'center',
                  width: '100%',
                  padding: 0,
                  margin: 0,
                  fontSize: '11px',
                }}
              >
                Chat id: {description.split('/')[1]}
              </Text>
              <Text
                style={{
                  color: 'white',
                  fontWeight: '700',
                  textAlign: 'center',
                  width: '100%',
                  padding: 0,
                  margin: 0,
                  fontSize: '11px',
                }}
              >
                {frequency_type === 'WEEKLY' &&
                  'Renueva cada: ' + frequency_value + ' Semana'}
                {frequency_type === 'DAILY' &&
                  'Renueva cada: ' + frequency_value + ' Dia(s)'}
                {frequency_type === 'MONTHLY' &&
                  'Renueva cada: ' + frequency_value + ' Mes(s)'}
              </Text>
              <Text
                style={{
                  color: 'white',
                  textAlign: 'center',
                  width: '100%',
                  padding: 0,
                  margin: 0,
                  fontSize: '13px',
                }}
              >
                Precio: {amount} {country != null ? country : 'USD'}
              </Text>
            </VStack>
            <Box
              style={{
                width: 'auto',
                height: '100%',
              }}
              display="flex"
              alignItems="flex-start"
            >
              {!personita && <CloseButton onClick={handleEliminarPlan} />}
            </Box>
          </HStack>
        </SimpleGrid>
        <Button
          style={
            !personita
              ? {
                  marginTop: '15px',
                  minWidth: '181px',
                  width: '100%',
                  backgroundImage: 'linear-gradient(to top, #22103d, #6e264b)',
                }
              : {
                  backgroundImage: 'linear-gradient(to top, #22103d, #6e264b)',
                  marginTop: '15px',
                  minWidth: minimoWitdth,
                  width: '100%',
                }
          }
          size={buttonSize}
        >
          <small
            style={{ cursor: 'pointer' }}
            onClick={() => {
              onCopy(plan_token);
            }}
          >
            {hasCopied ? '¡Copiado!' : '🔗Copiar Enlace'}
          </small>
        </Button>
        {isModalOpen && (
          <SubscriptionModalDos
            isOpen={isModalOpen}
            onClose={closeModal}
            isMobile={window.innerWidth <= 767}
            subscriptionDataArray={subscriptionData}
            datosPlan={datos}
          />
        )}
      </Box>
    );
};

export default PlanCard;
