import {
  Box,
  chakra,
  Container,
  Link,
  Stack,
  Text,
  useColorModeValue,
  useBreakpointValue,
  VisuallyHidden,
} from '@chakra-ui/react';
import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import { ReactNode, useEffect, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

const Logo = (props: any) => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path
          fillRule="nonzero"
          d="M16 4a1 1 0 0 1 1 1v4.2l5.213-3.65a.5.5 0 0 1 .787.41v12.08a.5.5 0 0 1-.787.41L17 14.8V19a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h14zM7.4 8.829a.4.4 0 0 0-.392.32L7 9.228v5.542a.4.4 0 0 0 .542.374l.073-.036 4.355-2.772a.4.4 0 0 0 .063-.624l-.063-.05L7.615 8.89A.4.4 0 0 0 7.4 8.83z"
        ></path>
      </g>
    </svg>
  );
};

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode,
  label: string,
  href: string,
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function SmallCentered() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Función para verificar el ancho de la pantalla y actualizar el estado
    const checkScreenSize = () => {
      if (window.innerWidth < 480) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Ejecutar la función al cargar la página y al cambiar el tamaño de la ventana
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    // Limpiar el evento al desmontar el componente
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const breakpointSize = useBreakpointValue({
    sm: '11px',
    md: '15px',
    lg: '17px',
    xl: '25px',
  });
  const showPrivacy = () => {
    NiceModal.show('infomodal');
  };
  const letras = useColorModeValue('#f0dbeb', '#f0dbeb');
  return (
    <Box
      style={{
        backgroundImage: 'linear-gradient(to top, #22103d, #6e264b)',
      }}
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('#f0dbeb', '#f0dbeb')}
    >
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        spacing={4}
        justify={'center'}
        align={'center'}
      >
        <Stack direction={'row'} spacing={6}>
          <Text
            color={letras}
            fontSize={isMobile ? '10px' : breakpointSize}
            style={{ cursor: 'pointer' }}
          >
            {' '}
            estoy.live
          </Text>
          <Text
            color={letras}
            fontSize={isMobile ? '10px' : breakpointSize}
            style={{ cursor: 'pointer' }}
            onClick={() => showPrivacy()}
          >
            {' '}
            Politica de Privacidad{' '}
          </Text>
          <Text color={letras} fontSize={isMobile ? '10px' : breakpointSize}>
            hola@estoy.live
          </Text>
        </Stack>
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.700')}
      >
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ base: 'center', md: 'space-between' }}
          align={{ base: 'center', md: 'center' }}
        >
          <Text color={letras} fontSize={isMobile ? '10px' : breakpointSize}>
            Fonko LLC: 7925 NW 12th ST, Suite 109 Doral, FL 33126 © 2022 All
            rights reserved.
          </Text>
          <Stack direction={'row'} spacing={6}>
            <SocialButton label={'Twitter'} href={'#'}>
              <FaTwitter />
            </SocialButton>
            <SocialButton label={'YouTube'} href={'#'}>
              <FaYoutube />
            </SocialButton>
            <SocialButton label={'Instagram'} href={'#'}>
              <FaInstagram />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
