import { Select } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import axios from 'axios';

const countries = [
  { code: 'PY', name: 'Paraguay' },
  { code: 'PE', name: 'Perú' },
  { code: 'PA', name: 'Panamá' },
  { code: 'NG', name: 'Nigeria' },
  { code: 'MY', name: 'Malasia' },
  { code: 'MX', name: 'México' },
  { code: 'KE', name: 'Kenia' },
  { code: 'ID', name: 'Indonesia' },
  { code: 'GT', name: 'Guatemala' },
  { code: 'EC', name: 'Ecuador' },
  { code: 'CR', name: 'Costa Rica' },
  { code: 'CO', name: 'Colombia' },
  { code: 'CL', name: 'Chile' },
  { code: 'BR', name: 'Brasil' },
  { code: 'BO', name: 'Bolivia' },
  { code: 'AR', name: 'Argentina' },
  { code: 'UY', name: 'Uruguay' },
];

function CountrySelect({ onSelectCountry }) {
  const [pais, setPais] = useState();
  useEffect(() => {
    axios.get('https://get.geojs.io/v1/ip/geo.json').then(response => {
      sessionStorage.pais = response.data.country_code;
      setPais(response.data.country_code);
    });
  }, []);
  if (pais)
    return (
      <Select
        backgroundColor={'#f0dbeb'}
        placeholder="Seleccionar país"
        onChange={onSelectCountry}
        defaultValue={pais}
      >
        {countries.map(country => (
          <option key={country.code} value={country.code}>
            {country.name}
          </option>
        ))}
      </Select>
    );
}

export default CountrySelect;
