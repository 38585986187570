import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  Center,
  VStack,
  Spinner,
} from '@chakra-ui/react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import ReactGA from 'react-ga';
import SliderPreview from './SliderPreview';
import SaleCardWrapper from './SaleCardWrapper';
const InfoProducto = () => {
  let params = useParams();
  const [telegramId, setTelegramId] = useState(
    localStorage.telegram_id || null
  );

  const [loading, setLoading] = useState(false);
  const [loadingDesc, setLoadingDesc] = useState(false);
  const [descripcion, setDescripcion] = useState(
    'Todos los medios de pago locales y transferencias bancaras'
  );

  const handlePagar = () => {
    setLoading(true);
    axios
      .get('https://server-mb9m.onrender.com/generar-link/' + params.hash)
      .then(res => {
        console.log(res);
        window.location.href = res.data.respuesta.redirect_url;
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoadingDesc(true);
    async function getDesc() {
      axios
        .get('https://server-mb9m.onrender.com/get-descripcion/' + params.hash)
        .then(res => {
          console.log(res.data.respuesta);
          setDescripcion(res.data.respuesta[0].descripcion);
          setLoadingDesc(false);
        });
    }
    getDesc();

    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <SaleCardWrapper>
      <Box width="100%" p={'25px'} boxShadow="md" borderRadius="md" bg="white">
        <Text fontSize={'61%'}>
          "{loadingDesc ? <Spinner /> : descripcion}"
        </Text>
      </Box>
      <Box width="100%" p={'25px'} boxShadow="md" borderRadius="md" bg="white">
        <SliderPreview hash={params.hash} />
      </Box>
      <Box width="100%" boxShadow="md" borderRadius="md" bg="white">
        <Button
          width={'100%'}
          onClick={handlePagar}
          isLoading={loading}
          style={{
            zIndex: '9999',
            background: 'black',
            color: 'white',
            borderTopRightRadius: '0',
            borderTopLeftRadius: '0',
          }}
        >
          D E S C A R G A R
        </Button>
      </Box>
    </SaleCardWrapper>
  );
};

export default InfoProducto;
