import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  Center,
  Image,
  InputGroup,
  InputRightElement,
  Tooltip,
} from '@chakra-ui/react';
import TelegramLoginButton from 'react-telegram-login';
import CountrySelect from './CountrySelect';
import axios from 'axios';

function TelegramLoginSubscriptor({ plan_id = '' }) {
  const currentURL = window.location.href;

  // Define la URL de la raíz de tu sitio
  const rootURL = 'https://estoy.live/';

  // Compara la URL actual con la URL de la raíz
  const isAtRoot = currentURL === rootURL;
  const [pais, setPais] = useState();
  useEffect(() => {
    axios.get('https://get.geojs.io/v1/ip/geo.json').then(response => {
      sessionStorage.pais = response.data.country_code;
      setPais(response.data.country_code);
    });
  }, []);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [telegramResponse, setTelegramResponse] = useState({
    id: '',
    username: '',
  });
  const [combinedData, setCombinedData] = useState({
    first_name: localStorage.firstName ? localStorage.firstName : '',
    last_name: localStorage.lastName ? localStorage.lastName : '',
    email: localStorage.email ? localStorage.email : '',
    telegram_id: localStorage.telegram_id ? localStorage.telegram_id : '',
    username: '',
    plan_id: null,
    hash: localStorage.hash,
  });

  const handleTelegramResponse = response => {
    // Aquí puedes manejar la respuesta de Telegram, como guardarla en el estado o enviarla al servidor

    setTelegramResponse(response);

    console.log('la data combinada', combinedData);
    console.log('Respuesta de Telegram:', response);
  };

  useEffect(() => {
    // Cargar datos del localStorage si existen
    const storedFirstName = localStorage.getItem('firstName');

    const storedLastName = localStorage.getItem('lastName');
    const storedEmail = localStorage.getItem('email');

    if (storedFirstName && storedLastName && storedEmail) {
      setFormData(prevData => ({
        ...prevData,
        firstName: storedFirstName,
        lastName: storedLastName,
        email: storedEmail,
      }));
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!telegramResponse.id) {
          console.log(
            'Datos de Telegram incompletos, no se realizará la llamada.'
          );
          return;
        }
        localStorage.setItem('firstName', formData.firstName);
        localStorage.setItem('lastName', formData.lastName);
        localStorage.setItem('email', formData.email);
        localStorage.setItem('plan_id', combinedData.plan_id);
        localStorage.setItem('telegram_id', telegramResponse.id);
        if (formData.email === '') {
          console.log(
            'Alguno de los valores está vacío, no se realizará la llamada.' +
              JSON.stringify(combinedData)
          );
          setCombinedData({
            first_name: telegramResponse.first_name,
            last_name: telegramResponse.last_name,
            email: formData.email,
            telegram_id: telegramResponse.id,
            username: telegramResponse.username,
            plan_id: plan_id,
            hash: localStorage.hash,
          });
          console.log(JSON.stringify(combinedData));
        }

        const response = await axios.post(
          'https://server-mb9m.onrender.com/save-data-customer',
          combinedData
        );

        console.log(response.data);
        if (
          response.data.message === 'done' ||
          response.data.message === 'Data already exists, no insert needed'
        )
          window.location.href = localStorage.url_pago;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [telegramResponse, combinedData, formData]);

  const emailRegExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  function validarEmail(email) {
    return emailRegExp.test(email);
  }
  const isFormValid = validarEmail(formData.email);

  return (
    <Center>
      <VStack spacing={4} width="400px">
        <FormControl>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            name="email"
            isDisabled={isAtRoot}
            value={formData.email}
            onChange={handleInputChange}
          />
        </FormControl>
        {isFormValid ? (
          <Box>
            <TelegramLoginButton
              dataOnauth={handleTelegramResponse}
              botName="estoylive_bot"
            />
          </Box>
        ) : (
          <Tooltip label="Ingresa un email correcto" aria-label="Tooltip">
            <Image
              src="botonprovisorio.PNG"
              h={'49px'}
              w={'218px'}
              style={{ cursor: 'not-allowed' }}
            />
          </Tooltip>
        )}
      </VStack>
    </Center>
  );
}

export default TelegramLoginSubscriptor;
