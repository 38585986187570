import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';

const AppContext = createContext();

export function useAppContext() {
  return useContext(AppContext);
}

export function AppProvider({ children }) {
  const [formData, setFormData] = useState({
    amount: '',
    country: '0',
  });

  const [loadPaymentLinks, setLoadPaymentLinks] = useState([]);
  const [selectedThumbs, setSelectedThumbs] = useState([]);

  const createPaymentLink = async laData => {
    // Lógica para enviar formData al backend y crear un enlace de pago
    // Agregar el nuevo enlace de pago a createdPaymentLinks
    if (laData.selectedThumbs.length === 0) {
      console.log(laData.selectedThumbs.length);
      return;
    }
    axios
      .post('https://server-mb9m.onrender.com/crear-link-pago/', { laData })
      .then(res => {
        HandleLoadPaymentLinks();
      });
  };

  const HandleLoadPaymentLinks = async () => {
    const links = await axios.get(
      'https://server-mb9m.onrender.com/get-payment-links/' +
        localStorage.telegram_id
    );
    const tmpLink = [];
    console.log(links);
    if (links.data !== 'sin links') {
      links.data.respuesta.map(item => {
        const link_pago = {
          hash: item.hash,
          amount: item.amount,
          pais: item.pais,
        };
        tmpLink.push(link_pago);
      });
      setLoadPaymentLinks(tmpLink);
    } else {
      setLoadPaymentLinks(['sin links']);
    }
  };

  const value = {
    formData,
    setFormData,
    createPaymentLink,
    selectedThumbs,
    setSelectedThumbs,
    loadPaymentLinks,
    HandleLoadPaymentLinks,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
