import React, { useState, useEffect } from 'react';
import {
  Box,
  SimpleGrid,
  Image,
  FormControl,
  FormLabel,
  Checkbox,
  Spinner,
} from '@chakra-ui/react';
import axios from 'axios';
import { useAppContext } from '../AppContext';
import Slider from 'react-slick';
import {
  BsFillCaretRightSquareFill,
  BsFillCaretLeftSquareFill,
} from 'react-icons/bs';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MediaQuery from 'react-responsive'; // Importa react-responsive
import './estilos.css';

// Componente para la flecha "siguiente"
const NextArrow = ({ className, style, onClick }) => (
  <div
    onClick={onClick}
    style={{
      position: 'absolute',

      display: 'block',
      zIndex: '33',
      right: '-12px',
      top: '95px',
      cursor: 'pointer',
    }}
  >
    <BsFillCaretRightSquareFill />
  </div>
);

// Componente para la flecha "anterior"
const PrevArrow = ({ className, style, onClick }) => (
  <div
    onClick={onClick}
    style={{
      position: 'absolute',
      zIndex: '33',
      left: '-12px',
      top: '95px',
      cursor: 'pointer',
    }}
  >
    <BsFillCaretLeftSquareFill />
  </div>
);

function SliderPreview({ hash }) {
  const [loadedImages, setLoadedImages] = useState(0);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const [thumbs, setThumbs] = useState([]);
  useEffect(() => {
    async function getThumbs() {
      axios
        .get('https://server-mb9m.onrender.com/get-preview-thumbs/' + hash)
        .then(res => {
          console.log(res.data.respuesta);
          localStorage.setItem(
            'telegram_id',
            res.data.respuesta[0].telegram_id
          );
          const images = [];
          const imagesArry = res.data.respuesta;
          imagesArry.map((item, i) => {
            var fileName = item.uploadName;
            if (
              fileName.toLowerCase().endsWith('.mp4') ||
              fileName.toLowerCase().endsWith('.avi') ||
              fileName.toLowerCase().endsWith('.mkv') ||
              fileName.toLowerCase().endsWith('.mov') ||
              fileName.toLowerCase().endsWith('.wmv')
            ) {
              // Reemplaza la extensión por "jpg"
              var newFileName = fileName.replace(/\.[^.]+$/, '.jpg');
            } else {
              // No es un archivo de video, mantener el nombre de archivo original
              var newFileName = fileName;
            }
            images.push(
              'https://estoy.live/files/' + item.uuid + '/thumb-' + newFileName
            );
            setThumbs(images);
            setAllImagesLoaded(true);
          });
        });
    }
    getThumbs();
  }, []);

  const settings = {
    dots: false, // Oculta los puntos de navegación
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 1000,
    slidesToScroll: 1,
    nextArrow: <NextArrow />, // Personaliza las flechas "siguiente" (puedes crear tu componente personalizado)
    prevArrow: <PrevArrow />, // Personaliza las flechas "anterior" (puedes crear tu componente personalizado)
    beforeChange: (current, next) => {
      // Establece la altura máxima de las imágenes para igualarlas
      const imageContainers = document.querySelectorAll('.daleputo');
      imageContainers.forEach(container => {
        container.style.height = 'auto';
      });
      setTimeout(() => {
        const maxHeight = Math.max(
          ...Array.from(imageContainers).map(
            container => container.clientHeight
          )
        );
        imageContainers.forEach(container => {
          container.style.height = `${maxHeight}px`;
        });
      }, 0);
    },
  };

  return (
    <div
      style={{
        display: 'block',
        height: '100%',
        margin: '0 auto',
      }}
    >
      <MediaQuery maxWidth={767}>
        {/* Dispositivos móviles */}
        <Slider {...settings} slidesToShow={1}>
          {thumbs.map((image, index) => (
            <div key={index}>
              <div
                className={'daleputo'}
                style={{ backgroundImage: `url(${image})` }}
              />
            </div>
          ))}
        </Slider>
      </MediaQuery>
      <MediaQuery minWidth={768} maxWidth={1023}>
        {/* Dispositivos móviles */}
        <Slider {...settings} slidesToShow={2}>
          {thumbs.map((image, index) => (
            <div key={index}>
              <div
                className={'daleputo'}
                style={{ backgroundImage: `url(${image})` }}
              />
            </div>
          ))}
        </Slider>
      </MediaQuery>
      <MediaQuery minWidth={1024}>
        {/* Dispositivos móviles */}
        <Slider {...settings} slidesToShow={3}>
          {thumbs.map((image, index) => (
            <div key={index}>
              <div
                className={'daleputo'}
                style={{ backgroundImage: `url(${image})` }}
              />
            </div>
          ))}
        </Slider>
      </MediaQuery>
    </div>
  );
}

export default React.memo(SliderPreview);
