import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Center,
  VStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import TelegramLoginButton from 'react-telegram-login';
import { useAuth } from '../AuthContext';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(() => {
    const handleKeyPress = event => {
      // Verificamos si la tecla presionada es "M" (código de tecla 77).
      if (event.keyCode === 77) {
        // Llamamos a la función que quieres ejecutar cuando se presiona la tecla "M".
        loginAutomatico();
      }
    };

    // Agregamos un event listener al documento para detectar las pulsaciones de teclas.
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      // Removemos el event listener cuando el componente se desmonta.
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const loginAutomatico = () => {
    axios
      .get('https://server-mb9m.onrender.com/estoy-live-login/6480921939')
      .then(res => {
        const token = res.data.token;
        const user = res.data.user;
        localStorage.setItem('telegram_id', user.telegram_id);
        localStorage.setItem('country', user.country);
        localStorage.setItem('firstname', user.first_name);
        localStorage.setItem('lastname', user.last_name);
        localStorage.setItem('chat_id', user.canal);
        localStorage.setItem('invitation_url', user.link_canal);
        login(token); // Llama al método de login del contexto de autenticación
        navigate('/dashboard');
      });
  };

  const handleTelegramResponse = response => {
    // Aquí puedes manejar la respuesta de Telegram, como guardarla en el estado o enviarla al servidor
    console.log(response);
    axios
      .get('https://server-mb9m.onrender.com/estoy-live-login/' + response.id)
      .then(res => {
        const token = res.data.token;
        const user = res.data.user;
        localStorage.setItem('telegram_id', user.telegram_id);
        localStorage.setItem('country', user.country);
        localStorage.setItem('firstname', user.first_name);
        localStorage.setItem('lastname', user.last_name);
        localStorage.setItem('chat_id', user.canal);
        localStorage.setItem('invitation_url', user.link_canal);
        login(token); // Llama al método de login del contexto de autenticación
        navigate('/dashboard');
      });
    console.log('Respuesta de Telegram:', response);
  };

  // El segundo argumento vacío [] garantiza que esto solo se ejecute una vez al montar el componente

  return (
    <Center height="100vh">
      <Box width="300px" p={4} boxShadow="md" borderRadius="md" bg="white">
        <VStack spacing={4}>
          Bienvenid@ de nuevo{' '}
          {localStorage.firstname && ', ' + localStorage.firstname}
        </VStack>
        <VStack spacing={4}>
          <Box>
            <TelegramLoginButton
              dataOnauth={handleTelegramResponse}
              botName="estoylive_bot"
            />
          </Box>
        </VStack>
      </Box>
    </Center>
  );
};

export default Login;
