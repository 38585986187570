import React, { useState, useEffect } from 'react';
import { Box, Heading } from '@chakra-ui/react';

const Card = ({ title, children }) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('chakra-ui-color-mode') === 'dark') {
      localStorage.setItem('chakra-ui-color-mode', 'light');
      window.location.reload(true);
    }
    const checkScreenSize = () => {
      if (window.innerWidth < 480) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Ejecutar la función al cargar la página y al cambiar el tamaño de la ventana
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    // Limpiar el evento al desmontar el componente
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);
  return (
    <Box
      borderWidth="1px"
      p={4}
      m={'0 auto'}
      borderRadius="md"
      width={isMobile ? '85%' : '100%'}
      backgroundColor="#f0dbeb"
    >
      {children}
    </Box>
  );
};

export default Card;
