import React, { useState, useEffect } from 'react';
import { useTable } from 'react-table';
import { Table, Thead, Tbody, Tr, Th, Td, Badge } from '@chakra-ui/react';
import { FaTimes } from 'react-icons/fa';
import axios from 'axios';
import './estilos.css';

const SubscriptionTable = ({ data, isMobile }) => {
  const [eliminado, setEliminado] = useState(false);
  const [mobile, setMobile] = useState(isMobile);

  const defaultColumns = [
    {
      Header: 'Nombre',
      accessor: 'client_first_name',
      headerClassName: 'custom-th',
    },
    {
      Header: 'Apellido',
      accessor: 'client_last_name',
      headerClassName: 'custom-th',
    },
    {
      Header: 'Renueva',
      accessor: 'scheduled_date',
      headerClassName: 'custom-th',

      Cell: ({ value }) => {
        const formattedDate = value
          ? new Date(value).toLocaleDateString()
          : 'N/A';
        return <Badge colorScheme="green">{formattedDate}</Badge>;
      },
    },
    {
      Header: '',
      accessor: 'subscription_id', // Puedes usar cualquier valor único aquí
      headerClassName: 'custom-th',

      Cell: ({ row }) => (
        <button
          onClick={() => handleDeleteSubscription(row)} // Llama a la función para eliminar
        >
          {!eliminado ? <FaTimes /> : 'CANCELADO'}
        </button>
      ),
      className: 'custom-td',
    },
  ];

  const desktopColumns = [
    {
      Header: 'Nombre',
      accessor: 'client_first_name',
    },
    {
      Header: 'Apellido',
      accessor: 'client_last_name',
    },
    {
      Header: 'Inicio',
      accessor: 'created_at',
      Cell: ({ value }) => {
        const formattedDate = new Date(value).toLocaleDateString();
        return <span>{formattedDate}</span>;
      },
    },
    {
      Header: 'Renueva',
      accessor: 'scheduled_date',
      Cell: ({ value }) => {
        const formattedDate = value
          ? new Date(value).toLocaleDateString()
          : 'N/A';
        return <Badge colorScheme="green">{formattedDate}</Badge>;
      },
    },
    {
      Header: '¿Eliminar?',
      accessor: 'subscription_id', // Puedes usar cualquier valor único aquí
      Cell: ({ row }) => (
        <button
          onClick={() => handleDeleteSubscription(row)} // Llama a la función para eliminar
        >
          {!eliminado ? <FaTimes /> : 'CANCELADO'}
        </button>
      ),
    },
  ];
  console.log(mobile);
  const columns = mobile ? defaultColumns : desktopColumns;
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  const handleDeleteSubscription = row => {
    const isConfirmed = window.confirm(
      '¿Estás por cancelar la subscripcion activa de tu cliente?'
    );

    const plan_id = row.original.plan.id;
    const subscription_id = row.original.id; //

    if (isConfirmed) {
      // Realiza una solicitud GET para eliminar la suscripción
      axios
        .get(
          `https://server-mb9m.onrender.com/deactivate/${plan_id}/${subscription_id}/${localStorage.telegram_id}`,
          {
            headers: {
              Authorization: `${localStorage.token}`, // Usa el formato "Bearer token"
            },
          }
        )
        .then(response => {
          // Maneja la respuesta del servidor aquí
          if (response.status === 200) {
            setEliminado(true);
          } else {
            // Maneja el caso de error, por ejemplo, mostrando un mensaje al usuario
          }
        })
        .catch(error => {
          // Maneja errores de red u otros errores aquí
        });
    }
  };

  return (
    <Table {...getTableProps()} variant="striped" size="sm">
      <Thead>
        {mobile
          ? headerGroups.map(headerGroup => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Th
                    style={{ backgroundColor: 'black', color: 'white' }}
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                  </Th>
                ))}
              </Tr>
            ))
          : headerGroups.map(headerGroup => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Th
                    style={{ backgroundColor: 'black', color: 'white' }}
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                  </Th>
                ))}
              </Tr>
            ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>;
              })}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default React.memo(SubscriptionTable);
