import React, { useState, useEffect } from 'react';
import {
  Box,
  SimpleGrid,
  Image,
  FormControl,
  FormLabel,
  Checkbox,
  Spinner,
} from '@chakra-ui/react';
import axios from 'axios';
import { useAppContext } from '../AppContext';

function PreviewThumbs({ hash }) {
  const [show, setShow] = useState(false);
  const [loadedImages, setLoadedImages] = useState(0);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const [thumbs, setThumbs] = useState([]);
  useEffect(() => {
    async function getThumbs() {
      axios
        .get('https://server-mb9m.onrender.com/get-preview-thumbs/' + hash)
        .then(res => {
          console.log('thumbs:', thumbs);
          console.log('res.data.respuesta:', res.data.respuesta);
          if (res.data.respuesta === 'error') {
            setShow(false);
            return;
          }
          setThumbs(res.data.respuesta);
          setShow(true);
        });
    }
    getThumbs();
  }, []);
  if (show)
    return (
      <>
        <SimpleGrid columns={[3, 2, 2]} spacing={1} p={5}>
          {thumbs?.map((item, i) => {
            var fileName = item.uploadName;
            if (
              fileName.toLowerCase().endsWith('.mp4') ||
              fileName.toLowerCase().endsWith('.avi') ||
              fileName.toLowerCase().endsWith('.mkv') ||
              fileName.toLowerCase().endsWith('.mov') ||
              fileName.toLowerCase().endsWith('.wmv')
            ) {
              // Reemplaza la extensión por "jpg"
              var newFileName = fileName.replace(/\.[^.]+$/, '.jpg');
            } else {
              // No es un archivo de video, mantener el nombre de archivo original
              var newFileName = fileName;
            }
            return (
              <Box
                key={i}
                position="relative"
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                backgroundColor={'black'}
              >
                <Image
                  src={
                    'https://estoy.live/files/' +
                    item.uuid +
                    '/thumb-' +
                    newFileName
                  }
                  w={'100%'}
                  alt="Imagen"
                  maxW="100%"
                  maxH="100%"
                />
              </Box>
            );
          })}
        </SimpleGrid>
      </>
    );
}

export default React.memo(PreviewThumbs);
