import {
  AvatarGroup,
  Avatar,
  AiOutlineUser,
  Box,
  Button,
  Center,
  chakra,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Heading,
  Text,
  HStack,
  VStack,
  Spinner,
} from '@chakra-ui/react';
import React, { ReactNode, useEffect, useState } from 'react';
import { BsPerson } from 'react-icons/bs';
import { MdAttachMoney } from 'react-icons/md';
import { HiOutlineChatAlt2 } from 'react-icons/hi';
import axios from 'axios';
import './estilos.css';

function UserProfile({ imageUrl }) {
  return (
    <Center>
      <Avatar size="sm">
        {imageUrl ? (
          <Box as="img" src={imageUrl} alt="Avatar" borderRadius="full" />
        ) : (
          <BsPerson size={30} /> // ajuste tamaño
        )}
      </Avatar>
    </Center>
  );
}

function StatsCard(props) {
  const { title, stat, icon, loading, claimed } = props;

  const [ocultarClaimButton, setOcultarClaimButton] = useState(false);
  const [claimLoading, setClaimLoading] = useState(false);
  const [resetStats, setResetStats] = useState(false);
  let tempClaimed = null;
  const handleClaim = () => {
    if (claimed > 0) {
      tempClaimed = claimed;
    }
    console.log(claimed);
    // Hacer la solicitud HTTP para reclamar el valor
    if (title === 'Ingresos') {
      setClaimLoading(true);
      setResetStats(true);

      axios
        .post(
          `https://server-mb9m.onrender.com/claim/${localStorage.telegram_id}`
        )
        .then(response => {
          if (response.status === 200) {
            // Actualizar el estado y establecer stat en 0
            // Puedes hacer esto con useState
            // Por ejemplo, si tienes un estado llamado claimedValue:
            setOcultarClaimButton(true);
            setClaimLoading(false);
            //puedo hacer suma y cuando paga en el response lo reseteo
            if (localStorage.claimed) {
              const temp = parseFloat(localStorage.claimed) + parseFloat(stat);
              localStorage.setItem('claimed', temp);
            } else {
              localStorage.setItem('claimed', stat);
            }
          }
        })
        .catch(error => {
          console.error('Error al reclamar el valor:', error);
        });
    }
  };
  const colorClaimed = useColorModeValue('green.800', 'green.500');
  const colorBorde = useColorModeValue('gray.800', 'gray.500');
  const boxColor = useColorModeValue('gray.800', 'gray.200');
  if (title !== 'Ingresos') {
    return (
      <Stat
        px={{ base: 2, md: 4 }}
        width={'100%'}
        py={'5'}
        shadow={'xl'}
        border={'1px solid'}
        borderColor={colorBorde}
        rounded={'lg'}
      >
        <Flex justifyContent={'space-evenly'}>
          <Box my={'auto'} color={boxColor} alignContent={'center'}>
            <HStack>
              <Box> {icon}</Box>
            </HStack>
          </Box>
          <Box pl={{ base: 2, md: 4 }}>
            <StatLabel fontWeight={'medium'} isTruncated>
              {title}
            </StatLabel>
            <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
              {loading ? <Spinner /> : stat}
            </StatNumber>
          </Box>
        </Flex>
      </Stat>
    );
  } else if (title === 'Ingresos') {
    return (
      <Stat
        px={{ base: 2, md: 4 }}
        width={'100%'}
        py={'5'}
        shadow={'xl'}
        border={'1px solid'}
        borderColor={colorBorde}
        rounded={'lg'}
      >
        <Flex justifyContent={'space-evenly'}>
          <Box my={'auto'} color={boxColor} alignContent={'center'}>
            <HStack>
              <Box> {icon}</Box>
            </HStack>
          </Box>
          <Box pl={{ base: 2, md: 4 }}>
            <StatLabel fontWeight={'medium'} isTruncated>
              {title}
            </StatLabel>
            <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
              <VStack>
                <HStack>
                  <Text fontSize={'13px'}>USD</Text>
                  <Box>
                    {claimLoading || loading ? (
                      <Spinner />
                    ) : resetStats ? (
                      '0'
                    ) : (
                      stat
                    )}
                  </Box>
                </HStack>
                {stat > 100 && !ocultarClaimButton ? (
                  <Box>
                    {' '}
                    <Button
                      size="xs"
                      variant="outline"
                      colorScheme="green"
                      onClick={handleClaim}
                    >
                      Claim
                    </Button>
                  </Box>
                ) : (
                  stat > 100 &&
                  ocultarClaimButton && (
                    <StatLabel color={colorClaimed}>
                      {localStorage.claimed ? localStorage.claimed : stat}
                    </StatLabel>
                  )
                )}
              </VStack>
            </StatNumber>
            {claimed && !resetStats && (
              <HStack>
                <Text fontSize={'9px'}>USD</Text>
                <StatLabel color={colorClaimed}>
                  {tempClaimed > 0
                    ? parseFloat(claimed) + parseFloat(tempClaimed)
                    : claimed}
                </StatLabel>
              </HStack>
            )}
          </Box>
        </Flex>
      </Stat>
    );
  }
}

export default function Ganancias() {
  const [dataLoaded, setDataLoaded] = useState(false);
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const [income, setIncome] = useState();
  const [subscribers, setSubscribers] = useState();
  const [canales, setCanales] = useState();
  const [loading, setLoading] = useState(false);
  const [claimed, setClaimed] = useState();
  useEffect(() => {
    if (!dataLoaded) {
      setLoading(true);
      const fetchClaimed = axios.get(
        'https://server-mb9m.onrender.com/claimed/' + localStorage.telegram_id
      );

      const fetchIncome = axios.get(
        'https://server-mb9m.onrender.com/income/' + localStorage.telegram_id
      );
      const fetchSubscribers = axios.get(
        'https://server-mb9m.onrender.com/alltimesubscribers/' +
          localStorage.telegram_id
      );
      const fetchCanales = axios.get(
        'https://server-mb9m.onrender.com/canales/' + localStorage.telegram_id
      );
      Promise.all([fetchIncome, fetchSubscribers, fetchCanales, fetchClaimed])
        .then(([incomeRes, subscribersRes, canalesRes, claimedRes]) => {
          setIncome(incomeRes.data.total);
          localStorage.setItem('dolar', incomeRes.data.conversion);
          localStorage.setItem(
            'planes_montos',
            JSON.stringify(incomeRes.data.planes_montos)
          );
          if (claimedRes.data.total > 0) setClaimed(claimedRes.data.total);
          setSubscribers(subscribersRes.data.total);
          setCanales(canalesRes.data.total);
          setLoading(false);
          setDataLoaded(true);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });
    }
  }, []);

  return (
    <Box
      width={{ base: '100%', sm: '100%', md: '100%' }}
      pt={5}
      px={{ base: 2, sm: 12, md: 17 }}
      style={{
        borderTop: '1px solid rgb(204, 204, 204)',
        boxShadow: ' rgb(0 0 0 / 7%) 0px 14px 40px -13px inset', //ajustar valores
      }}
    >
      <HStack>
        <UserProfile
          imageUrl={localStorage.profile_pic ? localStorage.profile_pic : null}
        />
        <Heading size="small" mb={4}>
          Hola
          {localStorage.firstname &&
            ', ' +
              capitalizeFirstLetter(localStorage.firstname) +
              ' ' +
              capitalizeFirstLetter(localStorage.lastname)}
          !
        </Heading>
      </HStack>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
        <StatsCard
          title={'Ingresos'}
          stat={income}
          claimed={claimed}
          loading={loading}
          icon={<MdAttachMoney />}
        />
        <StatsCard
          title={'Subscriptores'}
          stat={subscribers}
          loading={loading}
          icon={<BsPerson />}
        />
        <StatsCard
          title={'Canales'}
          stat={canales}
          loading={loading}
          icon={<HiOutlineChatAlt2 />}
        />
      </SimpleGrid>
    </Box>
  );
}
