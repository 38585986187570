import {
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  useClipboard,
  HStack,
  SimpleGrid,
  Text,
  Spinner,
  Icon,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { FaGlobe } from 'react-icons/fa';
import { DeleteIcon } from '@chakra-ui/icons';
import PreviewThumbs from './PreviewThumbs';
import axios from 'axios';
import { useAppContext } from '../AppContext';

function PaymentLinkItem({ item, index }) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const { loadPaymentLinks, HandleLoadPaymentLinks } = useAppContext();
  const [show, setShow] = useState(false);
  const { hasCopied, onCopy } = useClipboard(
    `https://${window.location.hostname + '/infoproducto/' + item.hash}`
  );
  const flagUrl =
    item.pais != 0
      ? `https://flagcdn.com/w320/${item.pais.toLowerCase()}.png`
      : false;

  useEffect(() => {
    axios
      .get('https://server-mb9m.onrender.com/payment-link/' + item.hash)
      .then(res => setShow(res.data));
  }, [loadPaymentLinks, HandleLoadPaymentLinks]);

  const handleEliminar = () => {
    const confirmacion = window.confirm(
      '¿Realmente desea eliminar este link de pago?'
    );
    if (confirmacion) {
      axios
        .post('https://server-mb9m.onrender.com/eliminar-infoproducto/', {
          hash: item.hash,
        })
        .then(res => {
          HandleLoadPaymentLinks();
          setShow(false);
        });
    }
  };

  function getCurrencyCode(countryCode) {
    const country = countries.find(c => c.code === countryCode);
    if (country) {
      switch (country.code) {
        case 'PY':
          return 'PYG';
        case 'PE':
          return 'PEN';
        case 'PA':
          return 'USD';
        case 'NG':
          return 'NGN';
        case 'MY':
          return 'MYR';
        case 'MX':
          return 'MXN';
        case 'KE':
          return 'KES';
        case 'ID':
          return 'IDR';
        case 'GT':
          return 'GTQ';
        case 'EC':
          return 'USD'; // Cambiar según corresponda
        case 'CR':
          return 'CRC';
        case 'CO':
          return 'COP';
        case 'CL':
          return 'CLP';
        case 'BR':
          return 'BRL';
        case 'BO':
          return 'BOB';
        case 'AR':
          return 'ARS';
        case 'UY':
          return 'UYU';
        default:
          return 'USD'; // Moneda predeterminada en caso de no encontrar una coincidencia
      }
    }
    return 'USD'; // Moneda predeterminada si no se encuentra el país en la lista
  }
  const countries = [
    { code: 'PY', name: 'Paraguay' },
    { code: 'PE', name: 'Perú' },
    { code: 'PA', name: 'Panamá' },
    { code: 'NG', name: 'Nigeria' },
    { code: 'MY', name: 'Malasia' },
    { code: 'MX', name: 'México' },
    { code: 'KE', name: 'Kenia' },
    { code: 'ID', name: 'Indonesia' },
    { code: 'GT', name: 'Guatemala' },
    { code: 'EC', name: 'Ecuador' },
    { code: 'CR', name: 'Costa Rica' },
    { code: 'CO', name: 'Colombia' },
    { code: 'CL', name: 'Chile' },
    { code: 'BR', name: 'Brasil' },
    { code: 'BO', name: 'Bolivia' },
    { code: 'AR', name: 'Argentina' },
    { code: 'UY', name: 'Uruguay' },
  ];

  if (!show) {
    return <Spinner size="lg" />;
  } else {
    return (
      <HStack>
        <Box width={'90%'}>
          <AccordionItem key={index}>
            <AccordionButton>
              <AccordionIcon />
              <HStack>
                <Box>
                  {flagUrl ? (
                    <Box
                      marginLeft={'3px'}
                      borderRadius="50px"
                      backgroundImage={`url('${flagUrl}')`}
                      backgroundSize="cover"
                      backgroundPosition="center"
                      width="15px"
                      height="15px"
                      alt={item.pais}
                    ></Box>
                  ) : (
                    <FaGlobe />
                  )}
                </Box>
                <Box>
                  {index +
                    1 +
                    ' - ' +
                    item.amount +
                    ' ' +
                    getCurrencyCode(item.pais)}
                </Box>
              </HStack>
            </AccordionButton>
            <AccordionPanel>
              <SimpleGrid columns={2}>
                <Box>
                  <small
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      onCopy();
                    }}
                  >
                    {hasCopied ? '¡Copiado!' : '🔗Copiar'}
                  </small>
                </Box>
              </SimpleGrid>
              <PreviewThumbs hash={item.hash} />
            </AccordionPanel>
          </AccordionItem>
        </Box>
        <Box width={'10%'}>
          <Icon
            as={DeleteIcon}
            style={{ cursor: 'pointer' }}
            w={5}
            h={5}
            color="red.500"
            onClick={() => handleEliminar()}
          />
        </Box>
      </HStack>
    );
  }
}

export default PaymentLinkItem;
