import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  useToast,
  ChakraProvider,
  extendTheme,
} from '@chakra-ui/react';
import { useAppContext } from '../AppContext';

const countries = [
  { code: 'PY', name: 'Paraguay' },
  { code: 'PE', name: 'Perú' },
  { code: 'PA', name: 'Panamá' },
  { code: 'NG', name: 'Nigeria' },
  { code: 'MY', name: 'Malasia' },
  { code: 'MX', name: 'México' },
  { code: 'KE', name: 'Kenia' },
  { code: 'ID', name: 'Indonesia' },
  { code: 'GT', name: 'Guatemala' },
  { code: 'EC', name: 'Ecuador' },
  { code: 'CR', name: 'Costa Rica' },
  { code: 'CO', name: 'Colombia' },
  { code: 'CL', name: 'Chile' },
  { code: 'BR', name: 'Brasil' },
  { code: 'BO', name: 'Bolivia' },
  { code: 'AR', name: 'Argentina' },
];

const theme = extendTheme({
  components: {
    Select: {
      baseStyle: {
        color: 'black',
        background: 'white',
        fontSize: '12px',
      },
    },
  },
});

function CrearLinkPagoForm({ triggerReloadLinks, cambiarEstadoLinksPago }) {
  const {
    formData,
    setFormData,
    createPaymentLink,
    selectedThumbs,
    loadPaymentLinks,
  } = useAppContext();

  const [isLoading, setIsLoading] = useState();

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const toast = useToast();
  const handleSubmit = e => {
    e.preventDefault();
    // Aquí puedes manejar los datos del formulario, como enviarlos a un servidor o realizar alguna acción.
    console.log(selectedThumbs);
    const dataToSend = {
      telegram_id: localStorage.telegram_id,
      amount: formData.amount,
      country: formData.country,
      selectedThumbs: selectedThumbs.filter(thumbs => thumbs.selected), // Combina los datos
    };
    if (selectedThumbs.length > 0 && formData.amount > 0) {
      createPaymentLink(dataToSend);
      triggerReloadLinks();
      selectedThumbs.length = 0;
    } else {
      toast({
        title: `${
          selectedThumbs.length === 0
            ? 'Debes seleccionar al menos un archivo '
            : ' '
        } ${
          formData.amount === 0 ? 'Debes establecer un precio mayor a 0.' : '.'
        }`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }

    console.log(dataToSend);
  };

  return (
    <ChakraProvider theme={theme}>
      <Box p={4}>
        <form onSubmit={handleSubmit}>
          <FormControl mb={4}>
            <FormLabel fontSize={'12px'}>Precio</FormLabel>
            <Input
              type="number"
              name="amount"
              value={formData.amount}
              onChange={handleInputChange}
              color="black"
              background={'white'}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel fontSize={'12px'}>País</FormLabel>
            <Select
              name="country"
              value={formData.country}
              color="black"
              background={'white'}
              onChange={handleInputChange}
            >
              <option value="0">Todos</option>
              {countries.map(country => (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <Button
            type="submit"
            minWidth="200px"
            colorScheme="blue"
            isLoading={isLoading}
            loadingText="Cargando..."
          >
            {isLoading ? 'Procesando...' : 'Crear'}
          </Button>
        </form>
      </Box>
    </ChakraProvider>
  );
}

export default CrearLinkPagoForm;
