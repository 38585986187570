import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Table,
  Thead,
  Text,
  HStack,
  Tbody,
  Center,
  Tr,
  Th,
  Td,
  VStack,
  Box,
  Badge,
} from '@chakra-ui/react';
import SubscriptionTable from './SubscriptionTable';

const SubscriptionModalDos = ({
  isOpen,
  onClose,
  subscriptionDataArray,
  isMobile,
  datosPlan,
}) => {
  useEffect(() => {
    console.log('aaper', subscriptionDataArray);
    localStorage.setItem('amount', subscriptionDataArray[0].plan.amount);
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent padding={'53px 0'}>
        <VStack>
          <Box>
            <ModalHeader pb={0}>Subscripciones:</ModalHeader>
          </Box>
          <Box mt={'0px !important'}>
            <HStack>
              <Text
                fontWeight="bold"
                color={'gray'}
                textTransform="uppercase"
                textAlign="center"
              >
                Plan:
              </Text>
              <Text
                fontWeight="bold"
                color={'black'}
                textTransform="uppercase"
                textAlign="center"
              >
                {datosPlan.titulo}
              </Text>
            </HStack>
            <Center>
              <small>
                {datosPlan.tipo === 'DAILY' &&
                  'Renueva cada: ' + datosPlan.frecuencia + ' Dia(s)'}
                {datosPlan.tipo === 'MONTHLY' &&
                  'Renueva cada: ' + datosPlan.frecuencia + ' Mes(s)'}
              </small>
            </Center>
            <Center>
              <Badge colorScheme="blue">
                USD {subscriptionDataArray[0].plan.amount}
              </Badge>
            </Center>
          </Box>
        </VStack>
        <ModalCloseButton />
        <ModalBody pt={'33px'}>
          <SubscriptionTable data={subscriptionDataArray} isMobile={isMobile} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SubscriptionModalDos;
