import {
  Heading,
  Avatar,
  Box,
  Center,
  Image,
  Flex,
  Text,
  Stack,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import TelegramLoginSubscriptor from './TelegramLoginSubscriptor';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import React, { useState, useEffect } from 'react';

export default function SaleCardWrapper({ children }) {
  let params = useParams();
  const [urlFoto, setUrlFoto] = useState();
  const [show, setShow] = useState(false);
  const boxColor = useColorModeValue('white', 'gray.800');
  useEffect(() => {
    axios
      .get('https://server-mb9m.onrender.com/profile/' + params.hash)
      .then(res => {
        setUrlFoto(
          'https://estoy.live/datos-user/' +
            res.data +
            '-1.jpg?timestamp=' +
            new Date().getTime()
        );
        setShow(true);
      });
  }, [show, setShow]);

  if (show)
    return (
      <Center py={6}>
        <Box
          maxW={['80vw', '300px', '470px']}
          minW={['79vw', '220px', '400px']}
          bg={boxColor}
          boxShadow={'2xl'}
          rounded={'md'}
          overflow={'hidden'}
        >
          <Image
            h={'120px'}
            w={'full'}
            src={'https://estoy.live/sale-card-background.png'}
            objectFit="cover"
            alt="#"
          />
          <Flex justify={'center'} mt={-12}>
            <Avatar
              size={'xl'}
              src={urlFoto}
              css={{
                border: '2px solid white',
              }}
            />
          </Flex>
          <Box p={6} heigth={'100%'}>
            {children}
          </Box>
        </Box>
      </Center>
    );
}
