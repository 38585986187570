import React from 'react';
import Hero from './Hero';
import Footer from './Footer';
import Features1 from './Features1';
import Features2 from './Features2';
import SignUpFake from './SignUpFake';
import Nav from './FakeNavBar';
import { Container } from '@chakra-ui/react';

export default function LandingPage() {
  return (
    <>
      <Nav />
      <Hero />

      <Features2 />
      <SignUpFake />
      <Footer />
    </>
  );
}
