import { ReactNode, useEffect, useState, useRef } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Center, Box, VStack, Spinner } from '@chakra-ui/react';
import useAxios from '../hooks/useAxios';
import SaleCard from './SaleCard';

export default function Home() {
  let params = useParams();

  const { response, loading, error } = useAxios({
    method: 'get',
    url: '/cargar-subscripcion/' + params.hash,
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    localStorage.setItem(
      'url_pago',
      'https://checkout.dlocalgo.com/validate/subscription/' + params.hash
    );
    try {
      if (response !== null) {
        console.log(response.rows);
        setData(response.rows[0]);
        const datos = response.rows[0];
        localStorage.setItem('plan_id', datos.plan_id);
        localStorage.setItem('plan_token', params.hash);
        localStorage.setItem('plan_pic', datos.usuario_creador_id);
        /*localStorage.setItem('creadora_id', response[0].avatar);
        localStorage.setItem('modelo', response[0].nick);
        localStorage.setItem('frase', response[0].frase);*/
      }
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }, [response]);

  const navigate = useNavigate();

  return (
    <Center height="100vh">
      <Box p={4} boxShadow="md" borderRadius="md" bg="white">
        <VStack spacing={4}>
          {loading ? (
            <>
              <Spinner />
              <p>Cargando...</p>
            </>
          ) : (
            <div>
              {error && (
                <div>
                  <p>{error.message}</p>
                </div>
              )}
              {data && <SaleCard props={data} />}
            </div>
          )}
        </VStack>
      </Box>
    </Center>
  );
}
