import React, { ReactNode, useState } from 'react';
import {
  Box,
  Flex,
  Avatar,
  Link,
  Button,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
  HStack,
  Text,
  Icon,
  useBreakpointValue,
  IconButton,
  createIcon,
} from '@chakra-ui/react';
import { Link as ScrollLink } from 'react-scroll';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import { FaBars } from 'react-icons/fa'; // Importa el ícono de hamburguesa
import { BsPeopleFill } from 'react-icons/bs';
import { BiLogIn } from 'react-icons/bi';

const PlayIcon = createIcon({
  displayName: 'PlayIcon',
  viewBox: '0 0 58 58',
  d: 'M28.9999 0.562988C13.3196 0.562988 0.562378 13.3202 0.562378 29.0005C0.562378 44.6808 13.3196 57.438 28.9999 57.438C44.6801 57.438 57.4374 44.6808 57.4374 29.0005C57.4374 13.3202 44.6801 0.562988 28.9999 0.562988ZM39.2223 30.272L23.5749 39.7247C23.3506 39.8591 23.0946 39.9314 22.8332 39.9342C22.5717 39.9369 22.3142 39.8701 22.0871 39.7406C21.86 39.611 21.6715 39.4234 21.5408 39.1969C21.4102 38.9705 21.3421 38.7133 21.3436 38.4519V19.5491C21.3421 19.2877 21.4102 19.0305 21.5408 18.8041C21.6715 18.5776 21.86 18.3899 22.0871 18.2604C22.3142 18.1308 22.5717 18.064 22.8332 18.0668C23.0946 18.0696 23.3506 18.1419 23.5749 18.2763L39.2223 27.729C39.4404 27.8619 39.6207 28.0486 39.7458 28.2713C39.8709 28.494 39.9366 28.7451 39.9366 29.0005C39.9366 29.2559 39.8709 29.507 39.7458 29.7297C39.6207 29.9523 39.4404 30.1391 39.2223 30.272Z',
});

// Función que renderiza un enlace de navegación
const NavLink = ({ children }: { children: ReactNode }) => (
  <Link
    px={2}
    py={1}
    rounded={'md'}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}
    href={'#'}
  >
    {children}
  </Link>
);

const Logo = (props: any) => {
  const { colorMode } = useColorMode();
  const logoSrc = colorMode === 'dark' ? '/nuevologo2.png' : '/nuevologo2.png';

  return (
    <HStack>
      <Box>
        <Image maxW={'75px'} src={logoSrc} />
      </Box>
      <Box fontFamily={'Mitr, sans-serif'} fontSize={'33px'} color={'white'}>
        estoy.live
      </Box>
    </HStack>
  );
};

export default function Nav() {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const bgColor = useColorModeValue('#0aa6cf', 'gray.900');
  const mobileMenuColor = useColorModeValue('white', 'gray.900');
  const botonColor = useColorModeValue('#30cef8', '#bbe7f7');
  const botonHoverBGColor = useColorModeValue('#1d1f28', '#30cef8');
  const botonColorMode = useColorModeValue('#bbe7f7', 'gray.900');
  const letrasBotonesIconos = useColorModeValue('#f0dbeb', 'black');

  // Agrega un estado para controlar el menú en dispositivos móviles
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const buttonSize = useBreakpointValue({ base: 'sm', md: 'lg' });
  return (
    <>
      <Box bg={useColorModeValue('#2a0020', 'gray.900')} px={4}>
        <Flex
          h={36}
          alignItems={'center'}
          justifyContent={'space-between'}
          px={35}
        >
          <HStack>
            <Logo />
          </HStack>

          <Flex alignItems={'center'}>
            <IconButton
              p={13}
              aria-label="Abrir menú"
              icon={<FaBars color="#f0dbeb" />}
              style={{
                backgroundImage: 'linear-gradient(to top, #22103d, #6e264b)',
              }}
              size={buttonSize}
              display={{ base: 'block', md: 'none' }} // Mostrar solo en dispositivos móviles
              onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
            />

            {/* Resto de tus botones y elementos del menú */}
            <Stack
              direction={'row'}
              spacing={7}
              display={{ base: 'none', md: 'block' }}
            >
              <Button
                onClick={() => {
                  window.location.href = '/login';
                }}
                rounded={'full'}
                size={buttonSize}
                fontWeight={'normal'}
                px={6}
                leftIcon={<BiLogIn h={4} w={4} color={'black'} />}
              >
                Iniciar Sesión
              </Button>
              <ScrollLink to="signUpFakeSection" smooth={true} duration={500}>
                <Button
                  rounded={'full'}
                  size={buttonSize}
                  fontWeight={'normal'}
                  px={6}
                  colorScheme={'red'}
                  bg={botonColor}
                  style={{
                    backgroundImage:
                      'linear-gradient(to top, #22103d, #6e264b)',
                  }}
                  color={'#f0dbeb'}
                  _hover={{ bg: botonHoverBGColor }}
                >
                  Regístrate
                </Button>
              </ScrollLink>
              <Button
                onClick={toggleColorMode}
                size={buttonSize}
                style={{
                  backgroundImage: 'linear-gradient(to top, #22103d, #6e264b)',
                }}
              >
                {colorMode === 'light' ? (
                  <MoonIcon color={'#f0dbeb'} />
                ) : (
                  <SunIcon color={'#f0dbeb'} />
                )}
              </Button>
            </Stack>
          </Flex>
        </Flex>

        {isMobileMenuOpen && (
          <Box
            bg={'transparent'}
            display={{ base: 'block', md: 'none' }} // Mostrar solo en dispositivos móviles
          >
            <Stack
              direction={'row'}
              spacing={1}
              justifyContent="center"
              alignItems="center"
              backgroundColor="transparent"
              pb={7}
            >
              <Button
                onClick={() => {
                  window.location.href = '/login';
                }}
                rounded={'full'}
                size={buttonSize}
                fontWeight={'normal'}
                px={6}
                leftIcon={<BsPeopleFill h={4} w={4} color={'black'} />}
              >
                Iniciar Sesión
              </Button>
              <ScrollLink to="signUpFakeSection" smooth={true} duration={500}>
                <Button
                  rounded={'full'}
                  size={buttonSize}
                  fontWeight={'normal'}
                  px={6}
                  colorScheme={'red'}
                  bg={botonColor}
                  style={{
                    backgroundImage:
                      'linear-gradient(to top, #22103d, #6e264b)',
                  }}
                  color={'#f0dbeb'}
                  _hover={{ bg: botonHoverBGColor }}
                >
                  Regístrate
                </Button>
              </ScrollLink>
              <Button
                onClick={toggleColorMode}
                size={buttonSize}
                style={{
                  backgroundImage: 'linear-gradient(to top, #22103d, #6e264b)',
                }}
              >
                {colorMode === 'light' ? (
                  <MoonIcon color={'#f0dbeb'} />
                ) : (
                  <SunIcon color={'#f0dbeb'} />
                )}
              </Button>
            </Stack>
          </Box>
        )}
      </Box>
    </>
  );
}
